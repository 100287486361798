import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Tag,
    Table,
    Card,
    Upload,
} from 'antd';
import axios from 'axios';
import Can from '../../components/Can';
import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../constant/url';
import {
    CheckCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    ImportOutlined,
    ExportOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';
import moment from 'moment';
import _ from 'underscore';

function Operator(history) {
    const columns = [
        // {
        //   title: " Date",
        //   dataIndex: "date",
        //   key: "date",
        //   sorter: false,
        //   render: (date) => (date ? moment(date).format("DD MMMM YYYY") : ""),
        // },
        // {
        //   title: "Shift",
        //   dataIndex: "shift_name",
        //   key: "shift_name",
        //   sorter: true,
        // },
        // {
        //   title: "Time",
        //   dataIndex: "shift",
        //   key: "shift",
        //   sorter: false,
        //   render: (shift, data) => {
        //     return {
        //       children: shift.time[0] + " - " + shift.time[1],
        //     };
        //   },
        // },
        {
            title: ' Name  ',
            dataIndex: 'employee_full_name',
            key: 'employee_full_name',
            sorter: false,
        },

        {
            title: 'Department',
            dataIndex: 'plan_name',
            key: 'plan_name',
        },
        {
            title: 'Main Process Name',
            dataIndex: 'main_processes',
            key: 'main_processes',
            sorter: false,
            // render: (main_process_name) => {
            //   return {
            //     children: (
            //       <div className={main_process_name.length <= 5 ? "" : "scroll"}>
            //         {main_process_name.map((i, index) => (
            //           <div key={index}>
            //             {index + 1}. {i}
            //           </div>
            //         ))}
            //       </div>
            //     ),
            //   };
            // },
        },
        {
            title: 'Sub Process Name',
            dataIndex: 'sub_processes',
            key: 'sub_processes',
            sorter: false,
        },
        {
            title: 'Line Process Name',
            dataIndex: 'line_processes',
            key: 'line_processes',
            sorter: false,

            // render: (mapping_process, data) => {
            //   return {
            //     children: mapping_process.line_processes,
            //   };
            // },
            // render: (part_detail, data) => {
            //   return {
            //     children: data.part_detail.map((part_detail) => {
            //       return (
            //         _.pluck(
            //           part_detail.mapping_process_detail,
            //           "line_process_name"
            //         ).join(", ") + ", "
            //       );
            //     }),
            //   };
            // },
        },

        // {
        //   title: "Supervisor",
        //   dataIndex: "manager_full_name",
        //   key: "manager_full_name",
        //   sorter: false,
        // },
        // {
        //   title: "Approval Status",
        //   dataIndex: "approve",
        //   key: "approve",
        //   sorter: false,
        //   render: (approve) => {
        //     return approve == true ? (
        //       <Tag icon={<CheckCircleOutlined />} color="success">
        //         Approved
        //       </Tag>
        //     ) : approve == false ? (
        //       <Tag icon={<CloseCircleOutlined />} color="error">
        //         Rejected
        //       </Tag>
        //     ) : (
        //       <Tag icon={<ClockCircleOutlined />} color="default">
        //         Waiting
        //       </Tag>
        //     );
        //   },
        // },
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (id) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    {/* <Menu.Item key="1">
                    <span onClick={() => handleApprove(id)}>
                      <CheckCircleOutlined /> Approve
                    </span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span onClick={() => handleReject(id)}>
                      <CloseCircleOutlined />
                      Reject
                    </span>
                  </Menu.Item> */}
                                    <Menu.Item key="3">
                                        <Link to={`/operator/edit/${id}`}>
                                            {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                            <EditOutlined /> Edit
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="4">
                                        <span onClick={() => handleDelete(id)}>
                                            {/* <i className="mdi mdi-delete mr-5" /> */}
                                            <DeleteOutlined />
                                            Delete
                                        </span>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(null);
    const [operator, setOperator] = useState([]);

    const [data, setData] = useState({
        file: null,
    });

    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/operator`, {
                params: {
                    keyword,
                    columns: [
                        'date',
                        'shift_id',
                        'shift_name',
                        'plan_id',
                        'plan_name',
                        'user_id',
                        'user_full_name',
                        'employee_full_name',
                        'manager_full_name',
                        'main_processes',
                        'line_processes',
                    ],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setOperator(res.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/operator/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const downloadTemplate = () => {
        setLoading(true);
        axios
            .get(`${url}/operator/download`, {
                params: {
                    keyword,
                    columns: [
                        'date',
                        'shift_id',
                        'shift_name',
                        'plan_id',
                        'plan_name',
                        'main_process_id',
                        'main_process_name',
                        'user_id',
                        'user_full_name',
                        'employee_full_name',
                        'manager_full_name',
                        'line_processes',
                        'main_processes',
                    ],
                  
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, 'export_operator.xlsx');
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        axios
            .post(`${url}/operator/import`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const handleApprove = (id) => {
        // console.log(id);
        setLoading(true);

        axios
            .post(
                `${url}/operator/approve/${id}`,
                {
                    approve: true,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    // setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const handleReject = (id) => {
        // console.log(id);
        setLoading(true);

        axios
            .post(
                `${url}/operator/reject/${id}`,
                {
                    approve: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    // setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Operator Movement</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        <Can accessTo="Create Operator">
                            <Link to="/operator/create">
                                <Button
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    <PlusOutlined />
                                    Add
                                </Button>
                            </Link>
                        </Can>
                        <Modal
                            title="Import Operator"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={closeModal}
                        >
                            <Card className="body-data">
                                <div name="control-hooks">
                                    <div name="file" label="File">
                                        <Fragment>
                                            <Upload
                                                onRemove={handleRemove}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={[]}
                                            >
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Choose File{' '}
                                                    <ExportOutlined />
                                                </Button>
                                            </Upload>
                                            {data.file && (
                                                <React.Fragment
                                                    children={[
                                                        <Button
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="danger"
                                                            ghost
                                                        >
                                                            <DeleteOutlined />
                                                        </Button>,
                                                    ]}
                                                >
                                                    {data.file.name
                                                        ? data.file.name
                                                        : data.file}
                                                </React.Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                    <div>
                                        <Button
                                            loading={loading}
                                            onClick={onSaveData}
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-button"
                                            style={{
                                                marginRight: 10,
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            htmlType="reset"
                                            onClick={handleRemove}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Modal>
                        <Can accessTo="Import Operator">
                            <Button
                                onClick={showModal}
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                    marginLeft: 10,
                                }}
                            >
                                <ImportOutlined />
                                Import
                            </Button>
                        </Can>
                        <Can accessTo="Export Operator">
                            <Button
                                loading={loading}
                                onClick={downloadTemplate}
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                    marginLeft: 10,
                                }}
                            >
                                <ExportOutlined />
                                Export
                            </Button>
                        </Can>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setKeyword(value);
                                setPage(1);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={operator.data}
                            pagination={{ current: page, total, showSizeChanger: true }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Operator;

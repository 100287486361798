import { Button, Col, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CycleTimeForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function CycleTimeForm({ history, match }) {
  const [data, setData] = useState({
   
    part_number: "",
    time_second: "",
    ct_process: "",
    line_processes: [],
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [parts, setParts] = useState([]);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/cycle-time/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // const { part_id, part_number } = res.data.data;
        setData({
          ...data,
        
          part_number: res.data.data.part_number,
          time_second: res.data.data.time_second,
          ct_process: res.data.data.ct_process,
          line_process_id: res.data.data.line_process_id,
          line_process_name: res.data.data.line_process_name,
        });
        console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      
      part_number: "",
      time_second: "",
      line_process_id: "",
      line_process_name: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log(data);
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/cycle-time/${match.params.id}`
          : `${url}/cycle-time`,
        {
        
          part_number: data.part_number,
          time_second: data.time_second,
          ct_process: data.ct_process,
          line_process_id: data.line_process_id,
          line_process_name: data.line_process_name,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/cycle-time");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/part/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/line-process/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_number`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  console.log(errors);

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/cycle-time">
            <LeftOutlined />
            Cycle Time
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Cycle Time" : "Add Cycle Time"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                Part Number <span className="error-text">*</span>
                <Input
                  placeholder="part_number"
                  value={data.part_number}
                  className={errors && errors.part_number ? "is-error" : ""}
                  onChange={(e) => handleChange("part_number", e.target.value)}
                />
                {errors && errors.part_number && (
                  <span className="error-text">{errors.part_number[0]}</span>
                )}
              </div>

              <div className="form-group">
                Line Process Name <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("line_process", value)
                  }
                  onFocus={() => getLine("")}
                  // onFocus={() => getLine()}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  value={{
                    key: data.line_process_id,
                    label: data.line_process_name,
                  }}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option value={line._id} key={line._id}>
                          {line.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_process_name && (
                  <span className="error-text">
                    {errors.line_process_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  CT Time <span className="error-text">*</span>
                </label>
                <Input
                  placeholder="second"
                  value={data.time_second}
                  className={errors && errors.time_second ? "is-error" : ""}
                  onChange={(e) => handleChange("time_second", e.target.value)}
                />
                {errors && errors.time_second && (
                  <span className="error-text">{errors.time_second[0]}</span>
                )}
              </div>

              <div className="form-group">
                CT Proses <span className="error-text">*</span>
                <Input
                  placeholder="CT Proses"
                  value={data.ct_process}
                  className={errors && errors.ct_process ? "is-error" : ""}
                  onChange={(e) => handleChange("ct_process", e.target.value)}
                />
                {errors && errors.ct_process && (
                  <span className="error-text">{errors.ct_process[0]}</span>
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default CycleTimeForm;

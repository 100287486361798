import React, { Fragment, useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    Select,
    Layout,
    Row,
    Col,
    Card,
    Breadcrumb,
    message,
  } from "antd";
  import { Link } from "react-router-dom";
  import "./PermissionForm.css";
  import axios from "axios";
  import moment from "moment";
  import { url } from "../../constant/url";
  
  const { Option } = Select;
  const { Content } = Layout;
  const { TextArea } = Input;
  
  const layout = {
    labelCol: {
      lg: 8,
      md: 10,
    },
    wrapperCol: {
      lg: 8,
      md: 14,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  function EditPermission(props) {
    const [data, setData] = useState({
      name: "",
      description: "",
      url: "",
      icon: "",
      order_number: "",
      parent_id: "",
      permission_type: "",
      parent_name: "",
    });

    const [types, setTypes] = useState([]);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parents, setParents] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getParents = async () => {
    await axios
      .get(`${url}/permission/list-parent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({
          ...data,
          permissions: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };


  const handleChangeSelectParent = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const getData = () => {
    axios.get(`${url}/permission/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          name: res.data.data.name,
          description: res.data.data.description,
          url: res.data.data.url,
          icon: res.data.data.icon,
          order_number: res.data.data.order_number,
          parent_id: res.data.data.parent_id,
          parent_name: res.data.data.parent_name,
          permission_type: res.data.data.permission_type,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };
  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getPermissionType = async () => {
    await axios.get(`${url}/settings/find`, {
      params: {
        variable: "permission_type",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveData = () => {
    setLoading(true);
    axios.post(
      `${url}/permission/${props.match.params.id}`,
      {
        name: data.name,
        description: data.description,
        url: data.url,
        icon: data.icon,
        order_number: data.order_number,
        parent_id: data.parent_id,
        parent_name: data.parent_name,
        permission_type: data.permission_type,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/permission");
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      name: "",
      description: "",
      url: "",
      icon: "",
      order_number: "",
      parent_id: "",
      parent_name: "",
      permission_type: "",
    });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Permission"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/permission">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Permission</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Name"
                  validateStatus={error && error.name ? "error" : false}
                  help={error && error.name ? error.name[0] : false}
                >
                  <Input
                    value={data.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Description"
                  validateStatus={error && error.description ? "error" : false}
                  help={
                    error && error.description ? error.description[0] : false
                  }
                >
                  <TextArea
                    value={data.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="URL"
                  validateStatus={error && error.url ? "error" : false}
                  help={error && error.url ? error.url[0] : false}
                >
                  <Input
                    value={data.url}
                    onChange={(e) => handleChange("url", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Icon"
                  validateStatus={error && error.icon ? "error" : false}
                  help={error && error.icon ? error.icon[0] : false}
                >
                  <Input
                    value={data.icon}
                    onChange={(e) => handleChange("icon", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Order Number"
                  validateStatus={error && error.order_number ? "error" : false}
                  help={
                    error && error.order_number ? error.order_number[0] : false
                  }
                >
                  <Input
                    value={data.order_number}
                    onChange={(e) =>
                      handleChange("order_number", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Parent"
                  validateStatus={error && error.parent_name ? "error" : false}
                  help={
                    error && error.parent_name ? error.parent_name[0] : false
                  }
                >
                <Select
                  style={{ width: '100%' }}
                  labelInValue
                  className={errors && errors.order_number ? "is-error" : ""}
                  onChange={(value) =>
                    handleChangeSelectParent("parent", value, data.id)
                  }
                  onFocus={() => getParents("")}
                  showSearch
                  onSearch={(value) => getParents(value)}
                  filterOption={false}
                  value={{
                    key: data.parent_id,
                    label: data.parent_name,
                  }}
                >
                  {data.permissions &&
                    data.permissions.map((permission) => {
                      return (
                        <Option value={permission._id} key={permission._id}>
                          {permission.name}
                        </Option>
                      );
                    })}
                </Select>
                </Form.Item>
                <Form.Item label="Type">
                  <Select
                    onChange={(value) => handleChange("permission_type", value)}
                    onFocus={() => getPermissionType()}
                    showSearch
                    onSearch={(value) => getPermissionType(value)}
                    filterOption={false}
                    value={data.permission_type}
                  >
                    {types &&
                      types.map((permission_type) => {
                        return (
                          <Option value={permission_type.name}>
                            {permission_type.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Update
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPermission;
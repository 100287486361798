import { Button, Col, Input, message, Row, Avatar, Upload, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../constant/url";
import profile from "../../assets/profile.jpg";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function EditMasterBOM({ history, match }) {
  const [data, setData] = useState({
    part_number: "",
    part_name: "",
    qty_snp: "",
    qty_child: "",
    route: "",
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [parts, setParts] = useState([]);
  const [partName, setPartName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/part/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };
  
  const getData = () => {
    axios.get(`${url}/master-bom/${match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        setData({
          ...data,
          part_number: res.data.data.part_number,
          part_name: res.data.data.part_name,
          part_id: res.data.data.part_id,
          qty_snp: res.data.data.qty_snp,
          qty_child: res.data.data.qty_child,
          route: res.data.data.route,

        });
        setPartName(res.data.data.part_name)
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  
  const handleReset = () => {
    setData({
      part_number: "",
      part_name: "",
      qty_snp: "",
      qty_child: "",
      route: "",
    });
  };

  
  const handleChangeSelectPart = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}_number`]: null,
      });
    }
  };

  const handleSelectPartNumber = (value, dataId) => {
    handleChangeSelectPart("part", value, dataId); // Call the original handleChangeSelectPart function
    const selectedPart = parts.find(part => part._id === value.key);
    setPartName(selectedPart ? selectedPart.name : ""); // Set the selected part name
  };

  const handleSave = (e) => {
    // console.log(data.email);
    e.preventDefault();
    let fd = new FormData();
   
    fd.set("part_id", data.part_id);
    fd.set("part_name", partName);
    fd.set("part_number", data.part_number);
    fd.set("qty_snp", data.qty_snp);
    fd.set("qty_child", data.qty_child);
    fd.set("route", data.route);
    setLoading(true);

    console.log(data)
    axios
      .post(
        `${url}/master-bom/${match.params.id}`, fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/master-bom");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };



 
  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/master-spcard">
            <LeftOutlined />
            Master BOM
          </Link>
        </li>
        <li className="active">
         Edit Master BOM
        </li>
      </ul>
      <div className="content">
      <form onSubmit={handleSave}>
          <Col span={12}></Col>
         
          <Row gutter={20}>
          <Col md={8} xs={24}>
          <div className="form-group">
                Part Number <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleSelectPartNumber(value, data._id) // Updated onChange event
                  }
                  onFocus={() => getPart("")}
                  showSearch
                  onSearch={(value) => getPart(value)}
                  filterOption={false}
                  value={{
                    key: data.part_id,
                    label: data.part_number,
                  }}
                >
                  {parts &&
                    parts.map((part) => {
                      return (
                        <Option value={part._id} key={part._id}>
                          {part.number}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.part_number && (
                  <span className="error-text">{errors.part_number[0]}</span>
                )}
              </div>
          <div className="form-group">
                Part Name <span className="error-text">*</span>
                <Select
                        allowClear
                        style={{ width: 693 }}
                        labelInValue
                        onChange={(value) =>
                          handleChangeSelectPart("part_name", value, data.id)
                        }
                       
                        showSearch
                        disabled
                        filterOption={false}
                        value={{
                          key: data.part_id,
                          label: partName, // Use the selected part name
                        }}
                      >
                        {parts &&
                          parts.map((part) => {
                            return (
                              <Option value={part._id} key={part._id}>
                                {part.name}
                              </Option>
                            );
                          })}
                      </Select>
                {errors && errors.part_name && (
                  <span className="error-text">{errors.part_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Qty SNP <span className="error-text">*</span>
                </label>
                <Input
                  value={data.qty_snp}
                  className={errors && errors.qty_snp ? "is-error" : ""}
                  onChange={(e) => handleChange("qty_snp", e.target.value)}
                />
                {errors && errors.qty_snp && (
                  <span className="error-text">{errors.qty_snp[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Qty Child <span className="error-text">*</span>
                </label>
                <Input
                value={data.qty_child}
                  
                  className={errors && errors.qty_child ? "is-error" : ""}
                  onChange={(e) => handleChange("qty_child", e.target.value)}
                />
                {errors && errors.qty_child && (
                  <span className="error-text">{errors.qty_child[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Routes <span className="error-text">*</span>
                </label>
                <Input
                  value={data.route}
                  className={errors && errors.route ? "is-error" : ""}
                  onChange={(e) => handleChange("route", e.target.value)}
                />
                {errors && errors.route && (
                  <span className="error-text">{errors.route[0]}</span>
                )}
              </div>

              
              <div className="form-group" style={{marginTop : 40}}>
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
          </Col>
         

          </Row>
        </form>
      </div>
    </div>
  );
}

export default EditMasterBOM;

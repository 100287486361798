import { message, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../assets/sanoh.png";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import Button from "../../components/Button/Button";
import { url } from "../../constant/url";
import "./Login.css";
import { Link } from "react-router-dom";

function Login({ history }) {
  const [login, setLogin] = useState({ username: "", password: "" });
  const [focus, setFocus] = useState({
    username: false,
    password: false,
  });
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [errors, setErrors] = useState({
    username: null,
    password: null,
  });

  const handleChange = (name, value) => {
    // setErrors({
    //   ...errors,
    //   [name]: null,
    // });
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
     
      const from = history.location.state?.from || '/employee';
      history.push(from);
    }
  }, [history]);

  const handleLogin = () => {
    // e.preventDefault();
    setLoading(true);
    axios
      .post(`${url}/login`, {
        username: data.username,
        password: data.password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("fullname", res.data.data.full_name);
        localStorage.setItem("photo", res.data.data.photo_url);
        localStorage.setItem("data", JSON.stringify(res.data.data));
        localStorage.setItem("menus", JSON.stringify(res.data.menus));
        localStorage.setItem("active", res.data.menus[0].name);
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        setLogin({
          ...login,
          username: "",
          password: "",
        });

        localStorage.setItem("redirect", res.data.redirect);
        message.success(res.data.message);
        setLoading(false);
        const url = localStorage.getItem('redirect');

        history.push(url);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setError(err.response.data.errorDetails);
          setErrors(err.response.data.errorDetails);
          message.error(err.response.data.message);
        } else {
          message.error(err);
        }
        setLoading(false);
      });
    console.log(errors);
  };

  const handleFocus = (name) => {
    setFocus({
      ...focus,
      [name]: true,
    });
  };

  const handleBlur = (name) => {
    setFocus({
      ...focus,
      [name]: false,
    });
  };

  // return !localStorage.getItem("token") ? (

  return (
    <div className="login">
      <img
        src={logo}
        className="kelola-logo"
        alt="logo"
        style={{
          width: 403,
          height: 164,
          display: "flex",
          marginBottom: 15,
          margin: "auto",
        }}
      />

      <Form onFinish={handleLogin}>
        <Form.Item>
          <Input
            style={{ marginBottom: 2 }}
            className="login-input"
            prefix={<UserOutlined />}
            placeholder="Username"
            onChange={(e) => handleChange("username", e.target.value)}
            value={data.username}
          />
          {errors && errors.username && (
            <span className="error-text">{errors.username[0]}</span>
          )}
        </Form.Item>
        <Form.Item>
          <Input
            className="login-input"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
            onChange={(e) => handleChange("password", e.target.value)}
            value={data.password}
          />
          {errors && errors.password && (
            <span className="error-text">{errors.password[0]}</span>
          )}
        </Form.Item>
        <div>
          <Button
            style={{ display: "flex", margin: "auto" }}
            htmlType="submit"
            loading={loading}
            type="primary"
            className="button-login"
          >
            Login
          </Button>
          <Link
            style={{ marginLeft: 240 }}
            className="button-forgot"
            to="/forgot-password"
          >
            Forgot Password ?
          </Link>
        </div>
      </Form>

      {/* <form onSubmit={handleLogin}>
        <div
          className={`login-input ${focus.email ? "login-input-focus" : ""} ${
            errors.message ? "login-input-danger" : ""
          }`}
        >
          <i className="mdi mdi-account" />
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => handleChange("email", e.target.value)}
            onFocus={() => handleFocus("email")}
            onBlur={() => handleBlur("email")}
          />
        </div>
        {errors.email && <span className="danger-text">{errors.email[0]}</span>}
        <div
          className={`login-input ${
            focus.password ? "login-input-focus" : ""
          } ${errors.password ? "login-input-danger" : ""}`}
        >
          <i className="mdi mdi-lock" />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => handleChange("password", e.target.value)}
            onFocus={() => handleFocus("password")}
            onBlur={() => handleBlur("password")}
          />
        </div>
        {errors.password && (
          <span className="danger-text">{errors.password[0]}</span>
        )}
        <div>
          <Button loading={loading} onClick={handleLogin} type="primary">
            Login
          </Button>
        </div>
      </form> */}
    </div>
  );
}

export default Login;

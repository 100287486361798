import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import React from 'react';

export default function ImportErrorModal({
    isVisible,
    setIsVisible,
    data: record,
}) {
    const onCancel = () => {
        setIsVisible(false);
    };

    const columns = [
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'Main Process',
            dataIndex: 'main_process',
            key: 'main_process',
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            align: 'center',
            render: text => <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>{text}</div>,
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            align: 'center',
            render: text => <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>{text}</div>,
        },
        {
            title: 'Date',
            dataIndex: 'production_date',
            key: 'production_date',
            align: 'center',
            render: text => <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>{text}</div>,
        },
        {
            title: 'Qty Planning',
            dataIndex: 'qty',
            key: 'qty',
            align: 'center',
            render: text => <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>{text}</div>,
        },
        {
            title: 'Total Problem',
            dataIndex: 'problem_count',
            key: 'problem_count',
            align: 'center',
            render: text => <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>{text}</div>,
        },
        {
            title: 'Problems',
            dataIndex: 'problems',
            key: 'problems',
            render: (_, record) => {
                return (
                    <ul style={{marginLeft: 0, paddingLeft: 0}}>
                        {record.problems &&
                            record.problems.map((problem, index) => (
                                <li key={index}>{problem}</li>
                            ))}
                    </ul>
                );
            },
        },
    ]
    return (
        <Modal
            title="Data Gagal Import"
            visible={isVisible}
            onCancel={onCancel}
            width={1200}
            footer={[
                <Button key="btn-cancel" type="ghost" onClick={onCancel}>
                    Close
                </Button>,
            ]}
        >
            <Typography.Text>
                Gagal import {record.total_problem_data} dari{' '}
                {record.total_data} data. Ditemukan {record.total_problem}{' '}
                error. Data yang ditampilkan adalah data yang memiliki error
            </Typography.Text>
            <Row>
                <Col span={24}>
                    <Table
                        scroll={{x: true}}
                        columns={columns}
                        dataSource={record.problem_data}
                        pagination={{
                            total: record.total_data,
                            showSizeChanger: true,
                        }}
                        rowKey={(data) => data.part_number}
                    />
                </Col>
            </Row>
        </Modal>
    );
}

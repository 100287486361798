import {
    Button, Form,
    InputNumber,
    Modal,
    Row, Space,
    message
} from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import Axios from '../../../../config/axios';

function GenerateLabelModal({
    isVisible,
    setIsVisible,
    onFinish,
    data: record,
    disabled,
}) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [form] = useForm();

    useEffect(() => {
        if (record) {
            const remaining = record.generate_remaining
            form.setFieldsValue({
                qty_spcard: record.qty_spcard,
                qty_erp: record.qty_erp,
                qty_current: record.qty_current,
                total: remaining,
            });
        }
    }, [record]);

    const onCancel = () => {
        setIsVisible(false);
    };

    const handleSubmit = (data) => {
        if (data.total === null || data.total < 1) {
            message.error('You must specify print amount!');
        } else {
            setLoading(true);
            const payload = {
                ...data,
                id: record && record._id,
            };
            Axios.post(`/generate-label/generate`, payload)
                .then(({ data }) => {
                    message.success(data.message);
                    setIsVisible(false);
                    onFinish();
                })
                .catch((error) => {
                    message.error(error.response.data.message);
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <React.Fragment>
            <Button
                type="primary"
                icon={<BarcodeOutlined />}
                onClick={() => setIsVisible(true)}
                disabled={disabled}
            >
                Generate S.P.CARD DEB
            </Button>
            <Modal
                title="Generate S.P.CARD DEB"
                visible={isVisible}
                onCancel={onCancel}
                width={600}
                footer={[
                    <Button key="btn-cancel" type="ghost" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="btn-import"
                        type="primary"
                        onClick={() => form.submit()}
                        disabled={
                            loading ||
                            (record && record.generate_remaining <= 0)
                        }
                    >
                        Submit
                    </Button>,
                ]}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th className="kelola-text-primary">
                                    Part Number
                                </th>
                                <th className="kelola-text-primary">
                                    Part Name
                                </th>
                                <th className="kelola-text-primary">
                                    Department
                                </th>
                            </tr>
                            <tr>
                                <td>{record && record.part_number}</td>
                                <td>{record && record.part_name}</td>
                                <td>{record && record.department}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={handleSubmit}
                        style={{ width: '100%' }}
                    >
                        <Row style={{ display: 'flex', gap: '2rem' }}>
                            <Form.Item label="Qty S.P. Card" name="qty_spcard">
                                <InputNumber disabled />
                            </Form.Item>
                            <Form.Item label="Qty Current" name="qty_current">
                                <InputNumber disabled />
                            </Form.Item>
                            <Form.Item
                                label="Qty ERP (SNP)"
                                name="qty_erp"
                            >
                                <InputNumber disabled />
                            </Form.Item>
                            <Form.Item
                                label="Total Print Label"
                                name="total"
                                rules={[
                                    {
                                        type: 'number',
                                        max:
                                            record && record.generate_remaining,
                                        min: 1,
                                    },
                                ]}
                            >
                                <InputNumber
                                    disabled={
                                        record && record.generate_remaining <= 0
                                    }
                                />
                            </Form.Item>
                        </Row>
                    </Form>
                </Space>
            </Modal>
        </React.Fragment>
    );
}

export default GenerateLabelModal;

import React, { useEffect, useState, Fragment } from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Upload,
  DatePicker,
  Card,
  Tag,
} from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    BookOutlined,
    ImportOutlined,
    ExportOutlined,
  } from "@ant-design/icons";
function ModalImport({
    title,
    data,
    visible,
    onCancel,
    onRemove,
    beforeUpload,
    onHandleRemove,
    onSaveData,
    onLoading
    }) {
  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
        
        <Card className="body-data">
                <div name="control-hooks">
                  <div name="file" label="File">
                    <Fragment>
                      <Upload
                        onRemove={onRemove}
                        beforeUpload={beforeUpload}
                        fileList={[]}
                      >
                        <Button style={{ marginBottom: 10 }}>
                          Choose File <ExportOutlined />
                        </Button>
                      </Upload>
                      {data.file && (
                        <React.Fragment
                          children={[
                            <Button onClick={onHandleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          {data.file.name ? data.file.name : data.file}
                        </React.Fragment>
                      )}
                    </Fragment>
                  </div>
                  <div>
                    <Button
                      loading={onLoading}
                      onClick={onSaveData}
                      type="primary"
                      htmlType="submit"
                      className="mr-button"
                      style={{
                        marginRight: 10,
                        backgroundColor: "#1b2086",
                        borderColor: "#1b2086",
                      }}
                    >
                      Upload
                    </Button>
                    <Button htmlType="reset" onClick={onHandleRemove}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Card>
    </Modal>
  )
}

export default ModalImport

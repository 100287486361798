import {
    Button,
    Col,
    Input,
    message,
    DatePicker,
    Row,
    Select,
    Table,
    Form,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import "./ProductionPlanningForm.css";
  import axios from "axios";
  import { url } from "../../constant/url";
  import { LeftOutlined } from "@ant-design/icons";
  import ProductionPlanning from "./ProductionPlanning";
  import moment from "moment";
  
  const { Option } = Select;
  const columnNames = [
    'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty',
    'twenty_one', 'twenty_two', 'twenty_three', 'twenty_four', 'twenty_five', 'twenty_six', 'twenty_seven', 'twenty_eight', 'twenty_nine', 'thirty',
    'thirty_one'
  ];
  function CreateProductionPlanning({ history, match }) {
    const [data, setData] = useState({
      plan_id: "",
      plan_name: "",
      line_process_id: "",
      line_process_name: "",
      main_process_id: "",
      main_process_name: "",
      part_id: "",
      part_number: "",
      month: "",
      year: "",
    });
  
    const [page] = useState(1);
    const [perpage] = useState(10);
    const [sort] = useState({
      sort: "created_at",
      order: "ascend",
    });
  
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [lines, setLines] = useState([]);
    const [mains, setMains] = useState([]);
    const [parts, setParts] = useState([]);
    const [productionPlannings, setProductionPlannings] = useState(
        Array(1).fill(
          Object.fromEntries(columnNames.map(name => [name, 0]))
        )
      );
  
    
  
    const handleChange = (name, value) => {
      setErrors({
        ...errors,
        [name]: "",
      });
  
      setData({
        ...data,
        [name]: value,
      });
    };
  
  
    const handleReset = () => {
      setData({
        plan_id: "",
        plan_name: "",
        line_process_id: "",
        line_process_name: "",
        line_processes: "",
        main_process_id: "",
        main_process_name: "",
        line_processes: "",
        part_id: "",
        part_number: "",
        month: "",
        year: "",
      });
    };
  
    const handleSave = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post(`${url}/production-planning`,
          {
            plan_id: data.plan_id,
            plan_name: data.plan_name,
            line_process_id: data.line_process_id,
            line_process_name: data.line_process_name,
            // line_processes: data.line_processes,
            main_process_id: data.main_process_id,
            main_process_name: data.main_process_name,
            // line_processes: data.line_processes,
            part_id: data.part_id,
            part_number: data.part_number,
            month: data.month,
            year: data.year,
            ...productionPlannings[0],
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          message.success(res.data.message);
          history.push("/production-planning");
        })
        .catch((err) => {
          if (err.response) {
            setErrors(err.response.data.errors);
            message.error(err.response.data.message);
          }
          setLoading(false);
        });
    };
  
    console.log(errors);
  
    const handleChangeSelect = (name, value) => {
      setErrors(null);
      if (value) {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
          [`${name}_number`]: value.label,
        });
      } else {
        setData({
          ...data,
          [`${name}_id`]: null,
          [`${name}_name`]: null,
          [`${name}_number`]: null,
        });
      }
    };
  
    const getDepartment = async (keyword) => {
      await axios
        .get(`${url}/master-area/list/departement`, {
          params: {
            keyword,
            limit: perpage,
            page,
            sortBy: `${sort.sort}:${sort.order}`,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setDepartments(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        });
    };
  
    const getMain = async (keyword) => {
      await axios
        .get(`${url}/line-process/listmain`, {
          params: {
            keyword,
            limit: perpage,
            department: data.plan_name,
            page,
            sortBy: `${sort.sort}:${sort.order}`,
            plan_id: data.plan_id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setMains(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        });
    };
  
    const getLine = async (keyword) => {
      await axios
        .get(`${url}/line-process/list`, {
          params: {
            keyword,
            limit: perpage,
            page,
            sortBy: `${sort.sort}:${sort.order}`,
            // plan_id: data.plan_id,
            // main_process_id: data.main_process_id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLines(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        });
    };
  
    const getPart = async (keyword) => {
      await axios
        .get(`${url}/master-spcard/listpart/data`, {
          params: {
            keyword,
            limit: perpage,
            page,
            sortBy: `${sort.sort}:${sort.order}`,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setParts(res.data);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        });
    };
  
    const handleChangeRow = (index, name, value) => {
      const newItems = [...productionPlannings];
      newItems[index][name] = value;
      setProductionPlannings(newItems);
    };
  
    function onChange(date, dateString) {
      console.log(date, dateString);
    }
  
    const columns = columnNames.map((name, i) => ({
        title: `${i + 1}`,
        dataIndex: name,
        sorter: false,
        render: (text, record, index) => (
          <Input
            width={400}
            allowClear
            value={record[name]}
            
            onChange={(e) => handleChangeRow(index, name, e.target.value)}
          />  
        ),
      }));
  
    return (
      <div>
        <ul className="breadcumb">
          <li>
            <Link to="/production-planning">
              <LeftOutlined />
              Production Planning
            </Link>
          </li>
          <li className="active">
            {match.params.id
              ? "Edit Production Planning"
              : "Add Production Planning"}
          </li>
        </ul>
        <div className="content">
          <form onSubmit={handleSave}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <div className="form-group">
                  Department <span className="error-text">*</span>
                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    onChange={(value) => handleChangeSelect("plan", value)}
                    onFocus={() => getDepartment("")}
                    showSearch
                    onSearch={(value) => getDepartment(value)}
                    filterOption={false}
                    value={{
                      key: data.plan_id,
                      label: data.plan_name,
                    }}
                  >
                    {departments &&
                      departments.map((dept) => {
                        return (
                          <Option value={dept._id} key={dept._id}>
                            {dept.name}
                          </Option>
                        );
                      })}
                  </Select>
                  {errors && errors.plan_name && (
                    <span className="error-text">{errors.plan_name[0]}</span>
                  )}
                </div>
  
                <div className="form-group">
                  Main Process <span className="error-text">*</span>
                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    onChange={(value) =>
                      handleChangeSelect("main_process", value)
                    }
                    onFocus={() => getMain("")}
                    // onFocus={() => getMain()}
                    showSearch
                    onSearch={(value) => getMain(value)}
                    filterOption={false}
                    value={{
                      key: data.main_process_id,
                      label: data.main_process_name,
                    }}
                    // value={data.main_processes}
                  >
                    {mains &&
                      mains.map((main) => {
                        return (
                          <Option
                            value={main.main_process}
                            key={main.main_process}
                          >
                            {main.main_process}
                          </Option>
                        );
                      })}
                  </Select>
                  {errors && errors.main_process_main_process_name && (
                    <span className="error-text">
                      {errors.main_process_name[0]}
                    </span>
                  )}
                </div>
  
               

                <div className="form-group">
                  Part Number <span className="error-text">*</span>
                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    onChange={(value) => handleChangeSelect("part", value)}
                    onFocus={() => getPart("")}
                    showSearch
                    onSearch={(value) => getPart(value)}
                    filterOption={false}
                    value={{
                      key: data.id,
                      label: data.part_number,
                    }}
                  >
                    {parts &&
                      parts.map((part) => {
                        return (
                          <Option value={part.id} key={part.id}>
                            {part.part_number}
                          </Option>
                        );
                      })}
                  </Select>
                  {errors && errors.part_number && (
                    <span className="error-text">{errors.part_number[0]}</span>
                  )}
                </div>

                {/* <div className="form-group">
                  Part Name <span className="error-text">*</span>
                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    onChange={(value) => handleChangeSelect("part", value)}
                    onFocus={() => getPart("")}
                    showSearch
                    onSearch={(value) => getPart(value)}
                    filterOption={false}
                    value={{
                      key: data._id,
                      label: data.part_number,
                    }}
                  >
                    {parts &&
                      parts.map((part) => {
                        return (
                          <Option value={part._id} key={part._id}>
                            {part.part_number}
                          </Option>
                        );
                      })}
                  </Select>
                  {errors && errors.part_number && (
                    <span className="error-text">{errors.part_number[0]}</span>
                  )}
                </div> */}
  
                <div className="form-group">
                  <label className="form-label">
                    Month & Year<span className="error-text">*</span>
                  </label>
  
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(value) => handleChange("month", value)}
                    format="MM YYYY"
                    picker="month"
                    // value={data.month}
                  />
                  {/* <Input
                    value={data.month}
                    className={errors && errors.month ? "is-error" : ""}
                    onChange={(e) => handleChange("month", e.target.value)}
                  /> */}
                  {errors && errors.month && (
                    <span className="error-text">{errors.month[0]}</span>
                  )}
                </div>
  
              
  
                <Table
                  size="large"
                  columns={columns}
                  rowKey={(data, index) => index}
                  dataSource={productionPlannings}
                  scroll={{ x: 4000 }}
                  style={{width:"100%"}}
                  
                />
                <div className="form-group">
                  <Button
                    type="primary"
                    onClick={handleSave}
                    loading={loading}
                    style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                  >
                    Submit
                  </Button>
  
                  <Button
                    htmlType="reset"
                    type="primary"
                    onClick={handleReset}
                    style={{
                      backgroundColor: "#1b2086",
                      marginLeft: 10,
                      borderColor: "#1b2086",
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }
  
  export default CreateProductionPlanning;
  
import { Button, Form, InputNumber, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import Axios from "../../../../config/axios";


function ConfirmationPrintOut({ isVisible, setIsVisible, onFinish, printRemaining, id }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const [form] = useForm();

    useEffect(() => {
        form.setFieldsValue({
            amount: printRemaining,
        });
    }, [id, form]);

    const onCancel = () => {
        setIsVisible(false)
    }

    const handlePrint = (data) => {
        if (data.amount === null || data.amount < 1) {
            setErrors({ errors: { amount: 'amount is required' } });
        } else {
            setLoading(true);
            Axios.post(`/printout-spcard/single/${id}`, data, { responseType: 'blob' })
                .then(({ data }) => {
                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);

                    setIsVisible(false);
                    onFinish();
                }).catch((error) => {
                    if (error.response.status === 500) {
                        setIsVisible(false);
                        message.error('Server error');
                    } else {
                        if (error.response) {
                            const reader = new FileReader();
                            reader.onload = function () {
                                const errorMessage = JSON.parse(reader.result);
                                setIsVisible(false);
                                message.error(errorMessage.message);
                            };
                            reader.readAsText(error.response.data);
                        } else {
                            console.error('Error:', error.message.message);
                        }

                        // message.error(error.response.message);
                    }
                }).finally(() => setLoading(false))
        }
    }
    return (
        <Modal
            title="Print SP Card"
            visible={isVisible}
            onCancel={onCancel}
            footer={[
                <Button
                    key="btn-cancel"
                    type='ghost'
                    onClick={onCancel}>Cancel</Button>,
                <Button
                    key="btn-import"
                    type='primary'
                    loading={loading}
                    onClick={() => form.submit()}>Print</Button>,
            ]}
        >
            <Form
                name='print-form'
                layout='horizontal'
                form={form}
                onFinish={handlePrint}

            >
                <p>Masukkan jumlah S.P. Card yang akan diprint</p>
                <Form.Item
                    name="amount"
                    rules={[{ type: "number", min: 1, max: printRemaining, required: true }]}
                    label="Total Print"
                >
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ConfirmationPrintOut
import { Button, DatePicker, Form, Modal, Select, message } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import Axios from '../../../../config/axios';
import moment from 'moment';
import fileDownload from 'js-file-download';

function ExportGenerateSPCard({ isVisible, setIsVisible }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null
    })
    const [dateRangeValue, setDateRangeValue] = useState(null)

    const [page] = useState(1);
    const [perpage] = useState(15);
    const [sort] = useState({
        sort: "created_at",
        order: "ascend",
    });

    const onCancel = () => {
        setDateRangeValue(null)
        setIsVisible(false)
    }

    const getDepartments = async (keyword = null) => {
        await Axios
            .get(`/plan/list`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                }
            })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleChangeSelectMultiple = (name, value) => {
        setSelectedDepartment({
            ...selectedDepartment,
            [name]: value,
        });
    };

    const handleDateRange = (e) => {
        setDateRange({
            startDate: e ? moment(e[0]).format('YYYY-MM-DD') : null,
            endDate: e ? moment(e[1]).format('YYYY-MM-DD') : null
        });
        setDateRangeValue(e)
    }

    const handleExport = () => {
        const objToArray = (data) =>
            Object.keys(data).reduce((newData, key) => {
                const values = data[key].map(item => item.value);
                if (values.length > 0) {
                    newData[key] = values;
                }
                return newData;
            }, {});

        const transformedData = objToArray(selectedDepartment);
        let payload = {
            dateRange,
            ...transformedData
        }

        setLoading(true);
        Axios.post('/generate-spcard/export-printout', payload, { responseType: 'blob' })
            .then(({ data }) => {
                fileDownload(data, 'print_out_sp_card.xlsx');
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    onCancel()
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            onCancel();
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Button type='primary' icon={<ExportOutlined />} onClick={() => setIsVisible(true)}>Export</Button>
            <Modal
                title="Export SP Card"
                visible={isVisible}
                onCancel={onCancel}
                footer={[
                    <Button
                        key="btn-cancel"
                        type='ghost'
                        onClick={onCancel}>Cancel</Button>,
                    <Button
                        key="btn-import"
                        type='primary'
                        disabled={loading}
                        onClick={handleExport}>Export</Button>,
                ]}
            >
                <Form layout='vertical'>
                    <Form.Item
                        label="Date"
                        name="daterange"
                    >
                        <DatePicker.RangePicker style={{ width: "100%" }} onCalendarChange={handleDateRange} value={dateRangeValue}/>
                    </Form.Item>
                    <Form.Item
                        label="Departemen"
                        name="departemen"
                    >
                        <Select
                            allowClear
                            onClear={() => setSelectedDepartment([])}
                            style={{ width: "100%" }}
                            labelInValue
                            onChange={(value) =>
                                handleChangeSelectMultiple("departemen", value)
                            }
                            onFocus={() => getDepartments()}
                            showSearch
                            onSearch={(value) => getDepartments(value)}
                            filterOption={false}
                            mode={"multiple"}
                            placeholder="Filter Departemen"
                        >
                            {departments && departments.map((dept) => (<Select.Option key={dept.name} value={dept.name}>{dept.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ExportGenerateSPCard
import { Button, Col, Input, message, Row, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './OperatorForm.css';
import axios from 'axios';
import { url } from '../../constant/url';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import _ from 'underscore';

function NgForm({ history, match }) {
    const [data, setData] = useState({
        name: '',
        plan_id: '',
        plan_name: '',
        main_processes: [],
        ng_detail_name: '',
    });
    const [details, setDetails] = useState([{ id: 1, name: '' }]);
    const handleChangeDetails = (id, value) => {
        const newDetails = details.map((detail) =>
            detail.id === id ? { ...detail, name: value } : detail,
        );
        setDetails(newDetails);
    };
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { Option } = Select;
    const [mainProcess, setMainProcess] = useState([]);

    const [departments, setDepartments] = useState([]);
    const [lineProcess, setLineProcess] = useState([]);

    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    useEffect(() => {
        if (match.params.id) {
            getData();
        }
    }, []);
    const handleDeleteRow = (id) => {
        const updatedDetails = details.filter((detail) => detail.id !== id);
        setDetails(updatedDetails);
    };
    const getData = () => {
        axios
            .get(`${url}/master-ng/${match.params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                console.log(res.data.data);
                setData({
                    ...data,

                    name: res.data.data.name,

                    plan_id: res.data.data.plan_id,
                    plan_name: res.data.data.plan_name,

                    main_processes: res.data.data.tbm_spcard_line_detail.map(
                        (x) => {
                            return {
                                key: x.line_process_id,
                                value: x.line_process_id,
                                label: x.line_process_name,
                            };
                        },
                    ),
                });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const handleReset = () => {
        setData({
            date: '',
            shift_id: '',
            shift_name: '',
            time: '',
            employee_id: '',
            employee_full_name: '',
            plan_id: '',
            plan_name: '',
            manager_id: '',
            manager_full_name: '',
            main_processes: '',
            line_processes: '',
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post(
                match.params.id
                    ? `${url}/master-ng/${match.params.id}`
                    : `${url}/master-ng`,
                {
                    name: data.name,

                    plan_id: data.plan_id,
                    plan_name: data.plan_name,

                    main_processes: JSON.stringify(data.main_processes),
                    ng_detail_name: JSON.stringify(
                        details.map((detail) => ({
                            ng_detail_name: detail.name,
                        })),
                    ),
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                message.success(res.data.message);
                history.push('/master-ng');
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    // const handleChangeSelect = (name, value) => {
    //   setErrors(null);

    //   setData({
    //     ...data,
    //     main_processes: [],
    //     line_processes: [],
    //     [`${name}_id`]: value.value,
    //     [`${name}_name`]: value.label,
    //     [`${name}_full_name`]: value.label,
    //   });
    // };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            setData({
                ...data,

                main_processes: [],

                [`${name}_id`]: value.value,
                [`${name}_name`]: value.label,
            });
        } else {
            setData({
                ...data,
                main_processes: [],
            });
        }
    };

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleAddRow = () => {
        const newId = details[details.length - 1].id + 1;
        setDetails([...details, { id: newId, name: '' }]);
    };

    const handleChangeSelectMultiple = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
    };

    const getMainProcess = async (keyword) => {
        await axios
            .get(`${url}/line-process/listmain`, {
                params: {
                    department: data.plan_name,
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setMainProcess(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getDepartment = async (keyword) => {
        await axios
            .get(`${url}/master-area/list/departement`, {
                params: {
                    keyword,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    return (
        <div>
            <ul className="breadcumb">
                <li>
                    <Link to="/operator">
                        <LeftOutlined />
                        Master Ng
                    </Link>
                </li>
                <li className="active">
                    {match.params.id ? 'Edit Master Ng' : 'Add Master Ng'}
                </li>
            </ul>
            <div className="content">
                <form onSubmit={handleSave}>
                    <Row gutter={20}>
                        <Col md={18} xs={24}>
                            {/* <div className="form-group">
                <label className="form-label">
                  Date <span className="error-text">*</span>
                </label>
                <DatePicker
                  style={{ width: 693 }}
                  value={match.params.id && moment(data.date)}
                  onChange={(value) => handleChange("date", value)}
                  format="DD MMMM YYYY"
                />
                {errors && errors.date && (
                  <span className="error-text">{errors.date[0]}</span>
                )}
              </div> 

              <div className="form-group">
                Shift <span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("shift", value)}
                  onFocus={() => getShift("")}
                  showSearch
                  onSearch={(value) => getShift(value)}
                  filterOption={false}
                  value={{
                    key: data.shift_id,
                    label: data.shift_name,
                  }}
                >
                  {shifts &&
                    shifts.map((shift) => {
                      return (
                        <Option value={shift._id} key={shift._id}>
                          {shift.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.shift_name && (
                  <span className="error-text">{errors.shift_name[0]}</span>
                )}
              </div> */}
                            <div className="form-group">
                                <label className="form-label">
                                    Name <span className="error-text">*</span>
                                </label>
                                <Input
                                    className={
                                        errors && errors.part_number
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('name', e.target.value)
                                    }
                                    value={data.name}
                                />
                                {errors && errors.name && (
                                    <span className="error-text">
                                        {errors.name[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Department <span className="error-text">*</span>
                                {/* <Input
                  value={data.plan_name}
                  onChange={(e) => handleChange("plan_name", e.target.value)}
                  rows={5}
                /> */}
                                <Select
                                    allowClear
                                    // disabled
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect('plan', value)
                                    }
                                    onFocus={() => getDepartment('')}
                                    showSearch
                                    onSearch={(value) => getDepartment(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.plan_id,
                                        label: data.plan_name,
                                    }}
                                >
                                    {departments &&
                                        departments.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.plan_name && (
                                    <span className="error-text">
                                        {errors.plan_name[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Main Process Name
                                <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'main_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getMainProcess('')}
                                    showSearch
                                    onSearch={(value) => getMainProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                >
                                    {mainProcess &&
                                        mainProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.main_process}
                                                    key={dept.main_process}
                                                >
                                                    {dept.main_process}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.main_process && (
                                    <span className="error-text">
                                        {errors.main_process[0]}
                                    </span>
                                )}
                            </div>
                            {details.map((detail, index) => (
                                <div
                                    key={detail.id}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <div
                                        className="form-group"
                                        style={{ flex: 1, marginRight: '8px' }}
                                    >
                                        {index === 0 && (
                                            <div>
                                                <span>NG Detail Name</span>
                                                <span className="error-text">
                                                    *
                                                </span>
                                            </div>
                                        )}
                                        <Input
                                            className={
                                                errors && errors.name
                                                    ? 'is-error'
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleChangeDetails(
                                                    detail.id,
                                                    e.target.value,
                                                )
                                            }
                                            style={{ width: '100%' }}
                                        />
                                        {errors && errors.name && (
                                            <span className="error-text">
                                                {errors.name[0]}
                                            </span>
                                        )}
                                    </div>
                                    {index === 0 ? (
                                        <Button
                                            type="primary"
                                            onClick={handleAddRow}
                                            loading={loading}
                                            style={{
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            + Add
                                        </Button>
                                    ) : (
                                        <Button
                                            type="danger"
                                            onClick={() =>
                                                handleDeleteRow(detail.id)
                                            }
                                            loading={loading}
                                            style={{
                                                backgroundColor: '#ff4d4f',
                                                borderColor: '#ff4d4f',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            ))}
                            <div className="form-group">
                                <Button
                                    type="primary"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" />  */}
                                    Submit
                                </Button>

                                <Button
                                    htmlType="reset"
                                    type="primary"
                                    onClick={handleReset}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        marginLeft: 10,
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" /> */}
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}

export default NgForm;

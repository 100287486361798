import React from "react";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import FullLayout from "./components/FullLayout/FullLayout";
import Login from "./pages/Login/Login";
import { Redirect } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import User from "./pages/User/User";
import UserForm from "./pages/User/UserForm";
import Role from "./pages/Role/Role";
import RoleForm from "./pages/Role/RoleForm";
import EditRole from "./pages/Role/EditRole";
import Permission from "./pages/Permission/Permission";
import PermissionForm from "./pages/Permission/PermissionForm";
import PermissionEdit from "./pages/Permission/EditPermission";
import Menu from "./pages/Menu/Menu";
import Setting from "./pages/Setting/Setting";
import SettingForm from "./pages/Setting/SettingForm";
import Employee from "./pages/Employee/Employee";
import EmployeeForm from "./pages/Employee/EmployeeForm";
import MainProcess from "./pages/MainProcess/MainProcess";
import MainProcessForm from "./pages/MainProcess/MainProcessForm";
import LineProcess from "./pages/LineProcess/LineProcess";
import LineProcessForm from "./pages/LineProcess/LineProcessForm";
import MappingProcess from "./pages/MappingProcess/MappingProcess";
import MappingProcessForm from "./pages/MappingProcess/MappingProcessForm";
import shift from "./pages/Shift/Shift";
import ShiftForm from "./pages/Shift/ShiftForm";
import Machine from "./pages/Machine/Machine";
import Shift from "./pages/Shift/Shift";
import MachineForm from "./pages/Machine/MachineForm";

// Master SP Card
import MasterSpCard from "./pages/MasterSpCard/MasterSpCard";
import MasterSpCardCreate from "./pages/MasterSpCard/CreateMasterSpCard";
import MasterSpCardEdit from "./pages/MasterSpCard/EditMasterSpCard";
import MasterSpCardEditQty from "./pages/MasterSpCard/EditQtyMasterSpCard";

// Master BOM
import MasterBOM from "./pages/MasterBOM/MasterBOM";
import MasterBOMCreate from "./pages/MasterBOM/CreateBOM";
import MasterBOMEdit from "./pages/MasterBOM/EditBOM";

// Master NG
import Ng from "./pages/NG/MasterNg";
import NgForm from "./pages/NG/NgForm";
import EditNg from "./pages/NG/EditNg";


import Model from "./pages/Model/Model";
import ModelForm from "./pages/Model/ModelForm";
import Part from "./pages/Part/Part";
import PartForm from "./pages/Part/PartForm";
import Customer from "./pages/Customer/Customer";
import CUstomerFOrm from "./pages/Customer/CustomerForm";
import CustomerForm from "./pages/Customer/CustomerForm";
import ItemCheck from "./pages/ItemCheck/ItemCheck";
import ItemCheckForm from "./pages/ItemCheck/ItemCheckForm";
import Operation from "antd/lib/transfer/operation";
import Operator from "./pages/Operator/Operator";
import OperatorForm from "./pages/Operator/OperatorForm";
import ProductionPlanning from "./pages/ProductionPlanning/ProductionPlanning";
import CreateProductionPlanning from "./pages/ProductionPlanning/CreateProductionPlanning";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Department from "./pages/Department/Department";
import DepartmentForm from "./pages/Department/DepartmentForm";
import BillOfMaterial from "./pages/BOM/BillOfMaterial";
import BillOfMaterialForm from "./pages/BOM/BillOfMaterialForm";
import Breakdown from "./pages/Breakdown/Breakdown";

import ProductionPlanningForm from "./pages/ProductionPlanning/ProductionPlanningForm";
import TesNG from "./pages/TesNG/TesNG";
import TesNGForm from "./pages/TesNG/TesNGForm";
import CycleTime from "./pages/CycleTime/CycleTime";
import CycleTimeForm from "./pages/CycleTime/CycleTimeForm";
import TesBreakdown from "./pages/TesBreakdown/TesBreakdown";
import TesBreakdownForm from "./pages/TesBreakdown/TesBreakdownForm";
import TesOperator from "./pages/TesOperator/TesOperator";
import TesOperatorForm from "./pages/TesOperator/TesOperatorForm";
import Andon from "./pages/Andon/Andon";
import Report from "./pages/Report/ReportTraceability";
import cobaDash from "./pages/Dashboard/CobaDash";
import MaterialItem from "./pages/MaterialItem/MaterialItem"
import MaterialItemForm from "./pages/MaterialItem/CreateItem"
import MaterialItemEdit from "./pages/MaterialItem/EditItem"

import MasterArea from "./pages/MasterArea/MasterArea"
import MasterAreaCreate from "./pages/MasterArea/CreateArea"
import MasterAreaEdit from "./pages/MasterArea/EditArea"
import MasterAreaForm from "./pages/MasterArea/LineProcessForm";

import GenerateSPCARD from "./pages/SPCARD/GenerateSPCARD/GenerateSPCARD";
import AddGenerateSPCARD from "./pages/SPCARD/GenerateSPCARD/AddGenerateSPCARD";
import GenerateLabelInternal from "./pages/SPCARD/GenerateLabelInternal/GenerateLabelInternal";

import GenerateLabel from "./pages/SPCARD/GenerateLabel/GenerateLabel"
import AddGenerateLabel from "./pages/SPCARD/GenerateLabel/AddGenerateLabel"

import PrintOutSPCard from "./pages/SPCARD/PrintOutSPCard/PrintOutSPCard";
import PrintOutLabel from "./pages/SPCARD/PrintOutLabelInternal/PrintOutLabel";

const router = [
  {
    path: "/",
    layout: BaseLayout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/dashboard",
    layout: BaseLayout,
    component: Dashboard,
  },

  {
    path: "/cobadash",
    layout: BaseLayout,
    component: cobaDash,
  },
  {
    path: "/user",
    layout: BaseLayout,
    component: User,
  },
  {
    path: "/user/create",
    layout: BaseLayout,
    component: UserForm,
  },
  {
    path: "/user/edit/:id",
    layout: BaseLayout,
    component: UserForm,
  },
  {
    path: "/login",
    layout: FullLayout,
    component: Login,
  },
  {
    path: "/role",
    layout: BaseLayout,
    component: Role,
  },
  {
    path: "/role/create",
    layout: BaseLayout,
    component: RoleForm,
  },
  {
    path: "/role/edit/:id",
    layout: BaseLayout,
    component: EditRole,
  },

  {
    path: "/permission",
    layout: BaseLayout,
    component: Permission,
  },
  {
    path: "/permission/create",
    layout: BaseLayout,
    component: PermissionForm,
  },
  {
    path: "/permission/edit/:id",
    layout: BaseLayout,
    component: PermissionEdit,
  },
  {
    path: "/menu",
    layout: BaseLayout,
    component: Menu,
  },
  {
    path: "/setting",
    layout: BaseLayout,
    component: Setting,
  },
  {
    path: "/setting/create",
    layout: BaseLayout,
    component: SettingForm,
  },
  {
    path: "/setting/edit/:id",
    layout: BaseLayout,
    component: SettingForm,
  },
  {
    path: "/master-item",
    layout: BaseLayout,
    component: MaterialItem,
  },
  {
    path: "/master-item/create",
    layout: BaseLayout,
    component: MaterialItemForm,
  },
  {
    path: "/master-item/edit/:id",
    layout: BaseLayout,
    component: MaterialItemEdit,
  },
  {
    path: "/employee",
    layout: BaseLayout,
    component: Employee,
  },
  {
    path: "/employee/create",
    layout: BaseLayout,
    component: EmployeeForm,
  },
  {
    path: "/employee/edit/:id",
    layout: BaseLayout,
    component: EmployeeForm,
  },
  {
    path: "/main-process",
    layout: BaseLayout,
    component: MainProcess,
  },
  {
    path: "/main-process/create",
    layout: BaseLayout,
    component: MainProcessForm,
  },
  {
    path: "/main-process/edit/:id",
    layout: BaseLayout,
    component: MainProcessForm,
  },
   {
    path: "/master-area",
    layout: BaseLayout,
    component: MasterArea,
  },
  {
    path: "/master-area/create",
    layout: BaseLayout,
    component: MasterAreaForm,
  },
  {
    path: "/master-area/edit/:id",
    layout: BaseLayout,
    component: MasterAreaForm,
  },
  
  {
    path: "/line-process",
    layout: BaseLayout,
    component: LineProcess,
  },
  {
    path: "/line-process/create",
    layout: BaseLayout,
    component: LineProcessForm,
  },
  {
    path: "/line-process/edit/:id",
    layout: BaseLayout,
    component: LineProcessForm,
  },
  {
    path: "/mapping-process",
    layout: BaseLayout,
    component: MappingProcess,
  },
  {
    path: "/mapping-process/create",
    layout: BaseLayout,
    component: MappingProcessForm,
  },
  {
    path: "/mapping-process/edit/:id",
    layout: BaseLayout,
    component: MappingProcessForm,
  },
  {
    path: "/shift",
    layout: BaseLayout,
    component: Shift,
  },
  {
    path: "/shift/create",
    layout: BaseLayout,
    component: ShiftForm,
  },
  {
    path: "/shift/edit/:id",
    layout: BaseLayout,
    component: ShiftForm,
  },
  {
    path: "/machine",
    layout: BaseLayout,
    component: Machine,
  },
  {
    path: "/machine/create",
    layout: BaseLayout,
    component: MachineForm,
  },
  {
    path: "/machine/edit/:id",
    layout: BaseLayout,
    component: MachineForm,
  },

  // MasterSpCard
  {
    path: "/master-spcard",
    layout: BaseLayout,
    component: MasterSpCard,
  },
  {
    path: "/master-spcard/create",
    layout: BaseLayout,
    component: MasterSpCardCreate,
  },
  {
    path: "/master-spcard/edit/:id",
    layout: BaseLayout,
    component: MasterSpCardEdit,
  },
  {
    path: "/master-spcard/edit-qty/:id",
    layout: BaseLayout,
    component: MasterSpCardEditQty,
  },
  // Master Bom

   {
    path: "/master-bom",
    layout: BaseLayout,
    component: MasterBOM,
  },
  {
    path: "/master-bom/create",
    layout: BaseLayout,
    component: MasterBOMCreate,
  },
  {
    path: "/master-bom/edit/:id",
    layout: BaseLayout,
    component: MasterBOMEdit,
  },

  {
    path: "/model",
    layout: BaseLayout,
    component: Model,
  },
  {
    path: "/model/create",
    layout: BaseLayout,
    component: ModelForm,
  },
  {
    path: "/model/edit/:id",
    layout: BaseLayout,
    component: ModelForm,
  },
  {
    path: "/part",
    layout: BaseLayout,
    component: Part,
  },
  {
    path: "/part/create",
    layout: BaseLayout,
    component: PartForm,
  },
  // {
  //   path: "/part/create/Welding",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Flaring",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Bending",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Cutting",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  {
    path: "/part/edit/:id",
    layout: BaseLayout,
    component: PartForm,
  },
  {
    path: "/customer",
    layout: BaseLayout,
    component: Customer,
  },
  {
    path: "/customer/create",
    layout: BaseLayout,
    component: CustomerForm,
  },
  {
    path: "/customer/edit/:id",
    layout: BaseLayout,
    component: CustomerForm,
  },
  {
    path: "/item-check",
    layout: BaseLayout,
    component: ItemCheck,
  },
  {
    path: "/item-check/create",
    layout: BaseLayout,
    component: ItemCheckForm,
  },
  {
    path: "/item-check/edit/:id",
    layout: BaseLayout,
    component: ItemCheckForm,
  },
  {
    path: "/operator",
    layout: BaseLayout,
    component: Operator,
  },
  {
    path: "/operator/create",
    layout: BaseLayout,
    component: OperatorForm,
  },
  {
    path: "/operator/edit/:id",
    layout: BaseLayout,
    component: OperatorForm,
  },
  {
    path: "/production-planning",
    layout: BaseLayout,
    component: ProductionPlanning,
  },
  {
    path: "/production-planning/edit/:id",
    layout: BaseLayout,
    component: ProductionPlanningForm,
  },

  {
    path: "/production-planning/create",
    layout: BaseLayout,
    component: CreateProductionPlanning,
  },
  {
    path: "/forgot-password",
    layout: FullLayout,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    layout: FullLayout,
    component: ResetPassword,
  },
  {
    path: "/department",
    layout: BaseLayout,
    component: Department,
  },
  {
    path: "/department/create",
    layout: BaseLayout,
    component: DepartmentForm,
  },
  {
    path: "/department/edit/:id",
    layout: BaseLayout,
    component: DepartmentForm,
  },
  {
    path: "/bill-of-material",
    layout: BaseLayout,
    component: BillOfMaterial,
  },
  {
    path: "/bill-of-material/create",
    layout: BaseLayout,
    component: BillOfMaterialForm,
  },
  {
    path: "/bill-of-material/edit/:id",
    layout: BaseLayout,
    component: BillOfMaterialForm,
  },
  {
    path: "/breakdown",
    layout: BaseLayout,
    component: Breakdown,
  },

  {
    path: "/master-ng",
    layout: BaseLayout,
    component: Ng,
  },
  {
    path: "/master-ng/create",
    layout: BaseLayout,
    component: NgForm,
  },
  {
    path: "/master-ng/edit/:id",
    layout: BaseLayout,
    component: EditNg,
  },
  {
    path: "/cycle-time",
    layout: BaseLayout,
    component: CycleTime,
  },
  {
    path: "/cycle-time/create",
    layout: BaseLayout,
    component: CycleTimeForm,
  },
  {
    path: "/cycle-time/edit/:id",
    layout: BaseLayout,
    component: CycleTimeForm,
  },
  {
    path: "/tes-ng",
    layout: BaseLayout,
    component: TesNG,
  },
  {
    path: "/tes-ng/create",
    layout: BaseLayout,
    component: TesNGForm,
  },
  {
    path: "/tes-ng/edit/:id",
    layout: BaseLayout,
    component: TesNGForm,
  },
  {
    path: "/tes-breakdown",
    layout: BaseLayout,
    component: TesBreakdown,
  },
  {
    path: "/tes-breakdown/create",
    layout: BaseLayout,
    component: TesBreakdownForm,
  },
  {
    path: "/tes-breakdown/edit/:id",
    layout: BaseLayout,
    component: TesBreakdownForm,
  },
  {
    path: "/tes-operator",
    layout: BaseLayout,
    component: TesOperator,
  },
  {
    path: "/tes-operator/create",
    layout: BaseLayout,
    component: TesOperatorForm,
  },
  {
    path: "/tes-operator/edit/:id",
    layout: BaseLayout,
    component: TesOperatorForm,
  },
  {
    path: "/generate-spcard",
    layout: BaseLayout,
    component: GenerateSPCARD,
  },
  {
    path: "/generate-spcard/create",
    layout: BaseLayout,
    component: AddGenerateSPCARD,
  },

  {
    path: "/generate-label",
    layout: BaseLayout,
    component: GenerateLabel,
  },
  {
    path: "/generate-label/create",
    layout: BaseLayout,
    component: AddGenerateLabel,
  },

  {
    path: "/generate-label-internal",
    layout: BaseLayout,
    component: GenerateLabelInternal,
  },
  {
    path: "/printout-spcard",
    layout: BaseLayout,
    component: PrintOutSPCard,
  },
  {
    path: "/printout-label",
    layout: BaseLayout,
    component: PrintOutLabel,
  },
  {
    path: "/andon",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/andon/:plan",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/andon/:plan/:main",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },

  {
    path: "/andon/:plan/:main/:line",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/report",
    // layout: ({ children }) => <div className="report">{children}</div>,
    layout: BaseLayout,
    component: Report,
  },
];

export default router;

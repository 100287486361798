import React from "react";
import colors from "./constant/colors";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import router from "./router";
import FullLayout from "./components/FullLayout/FullLayout";
import NotFound from "./pages/NotFound/NotFound";

const { width, height } = window.screen;

const GlobalStyles = createGlobalStyle`
  html {
    --color-primary: ${colors.primary};
    --color-secondary: ${colors.secondary};
    --color-stroke: ${colors.stroke};
    --color-active: ${colors.active};
    --color-black: ${colors.black};
    --color-background: ${colors.background};
    --color-border: ${colors.border};
    --color-danger: ${colors.danger};
    --width-navbar: ${width - 105}px;
    --width-content: ${width - 105}px;
    --height-content: ${height - 220}px;
  }
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Switch>
          {router.map((route, index) => {
            return (
              <Route
                path={route.path}
                exact
                key={index}
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              ></Route>
            );
          })}
          <Route
            component={(props) => {
              return (
                <FullLayout {...props}>
                  <NotFound {...props} />
                </FullLayout>
              );
            }}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;

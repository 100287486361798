import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import "./Dashboard.css";
import {
  message,
  Row,
  Col,
  Table,
  Card,
  Layout,
  Button,
  Space,
  Select,
  DatePicker,
} from "antd";
import Axios from '../../config/axios';
import axios from "axios";
import { url } from "../../constant/url";
import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";
import Can from "../../components/Can";
import { Fragment } from "react/cjs/react.production.min";
import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../components/AntNumberFormat";
import "chartjs-plugin-datalabels";

import _, { filter } from "underscore";

const { Content } = Layout;
const { Option } = Select;

const initialColumns = [
  {
    title: "Time",
    dataIndex: "resource",
    key: "resource",
    colSpan: 2,
  },
  {
    title: "Time",
    dataIndex: "unit",
    key: "unit",
    colSpan: 0,
  },
];


function Dashboard({ history }) {
  const [data, setData] = useState({
    plan_id: "",
    plan_name: "",
    main_process_id: "",
    main_process: "",
    main_process_name: "",
    line_process_id: "",
    line_process_name: "",
    part_id: "",
    part_number: "",
    user_id: "",
    user_full_name: "",
    start_date: moment().startOf("date"),
    shift_id: "",
    shift_name: "",
  });

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [itemData, setItemData] = useState({});
  const [Departments, setDepartments] = useState([]);
  const [Mains, setMains] = useState([]);
  
  const [Operators, setFOperator] = useState([]);
  const [Lines, setLines] = useState([]);
  const [parts, setParts] = useState([]);
  const [planperjam, setPlanPerJam] = useState([]);
  const [shiftTimes, setShiftTimes] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [sublevels, setSublevels] = useState([]);
  const [errors, setErrors] = useState(null);
  const [actplan, setActplan] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [subProcess, setSubProcess] = useState([]);
  const [cycleTimes, setCycleTimes] = useState([]);
  const [selectedShift, setSelectedShift] = useState({
    shift_id: "",
    shift_name: "",
  });

  const [dataNG, setDataNG] = useState({
    labels: [],
    data: [],
  });

  const [dataBreakdown, setDataBreakdown] = useState({
    labels: [],
    data: [],
  });

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getShifts();
  }, []);

  useEffect(() => {
    makeInitialData();
  }, []);

  const getShifts = () => {
    axios
      .get(`${url}/shift/list-new`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setShifts(res.data.data);

        setSelectedShift({
          shift_id: res.data.data[0]._id,
          shift_name: res.data.data[0].name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  useEffect(() => {
    generateTime();
  }, [selectedShift]);

  const generateTime = () => {
    if (selectedShift.shift_name === "All") {
      const times = [];
      const dataShiftTimes = [];

      shifts.map((x) => {
        const timeIn = moment(x.time_in, "HH:mm").format("H");
        const timeOut = moment(x.time_out, "HH:mm").format("H");

        let periodsInDay;

        if (parseInt(timeIn) > parseInt(timeOut)) {
          periodsInDay = moment
            .duration(
              moment(x.time_out, "HH:mm")
                .add(1, "day")
                .diff(moment(x.time_in, "HH:mm"))
            )
            .as("hours");
        } else {
          periodsInDay = moment
            .duration(
              moment(x.time_out, "HH:mm").diff(moment(x.time_in, "HH:mm"))
            )
            .as("hours");
        }

        const timeLabels = [];
        const dataShiftTimesLabels = [];

        for (let i = 0; i <= periodsInDay; i++) {
          dataShiftTimesLabels.push({
            time: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          });
          timeLabels.push({
            title: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
            dataIndex: moment(x.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            align: "center",
            key: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),

            render: (data_cycle_time, unit) => {
              if (unit.unit == "Second") {
                return <div>{Math.floor(data_cycle_time)}</div>;
              } else {
                return <div>{data_cycle_time}</div>;
              }
            },
          });
        }

        times.push(...timeLabels);
        dataShiftTimes.push(...dataShiftTimesLabels);
      });

      setColumns([...initialColumns, ...times]);
      setShiftTimes(dataShiftTimes);
    } else {
      const findShift = shifts.find((x) => x._id === selectedShift.shift_id);

      if (findShift) {
        const timeLabels = [];
        const dataShiftTimes = [];
        const timeIn = moment(findShift.time_in, "HH:mm").format("H");
        const timeOut = moment(findShift.time_out, "HH:mm").format("H");
        let periodsInDay;

        if (parseInt(timeIn) > parseInt(timeOut)) {
          periodsInDay = moment
            .duration(
              moment(findShift.time_out, "HH:mm")
                .add(1, "day")
                .diff(moment(findShift.time_in, "HH:mm"))
            )
            .as("hours");
        } else {
          periodsInDay = moment
            .duration(
              moment(findShift.time_out, "HH:mm").diff(
                moment(findShift.time_in, "HH:mm")
              )
            )
            .as("hours");
        }

        for (let i = 0; i <= periodsInDay; i++) {
          dataShiftTimes.push({
            time: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
          });
          timeLabels.push({
            title: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            dataIndex: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            align: "center",
            key: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),

            render: (data_cycle_time, unit) => {
              if (unit.unit == "Second") {
                return <div>{Math.floor(data_cycle_time)}</div>;
              } else {
                return <div>{data_cycle_time}</div>;
              }
            },
          });
        }
        setColumns([...initialColumns, ...timeLabels]);
        setShiftTimes(dataShiftTimes);
      }
    }
  };

  useEffect(() => {
    getGeneratePlan();
  }, [selectedShift]);

  console.log('Shift Times',shiftTimes);
  console.log('Item Data',itemData);

  const getGeneratePlan = () => {
    if (selectedShift.shift_name === "All") {
      const times = [];
      const dataShiftTimes = [];

      shifts.map((x) => {
        const timeIn = moment(x.time_in, "HH:mm").format("H");
        const timeOut = moment(x.time_out, "HH:mm").format("H");

        let periodsInDay;

        if (parseInt(timeIn) > parseInt(timeOut)) {
          periodsInDay = moment
            .duration(
              moment(x.time_out, "HH:mm")
                .add(1, "day")
                .diff(moment(x.time_in, "HH:mm"))
            )
            .as("hours");
        } else {
          periodsInDay = moment
            .duration(
              moment(x.time_out, "HH:mm").diff(moment(x.time_in, "HH:mm"))
            )
            .as("hours");
        }

        const timeLabels = [];
        const dataShiftTimesLabels = [];

        for (let i = 0; i <= periodsInDay; i++) {
          dataShiftTimesLabels.push({
            time: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          });
          timeLabels.push({
            title: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
            dataIndex: moment(x.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            align: "center",
            key: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),

            render: (data_table, unit) => {
              if (unit.unit == "%") {
                return <div>{Math.floor(data_table) + " %"}</div>;
              } else {
                return <div>{Math.floor(data_table)}</div>;
              }
            },
          });
        }

        times.push(...timeLabels);
        dataShiftTimes.push(...dataShiftTimesLabels);
      });

      setColumns([...initialColumns, ...times]);
      setShiftTimes(dataShiftTimes);
    } else {
      const findShift = shifts.find((x) => x._id === selectedShift.shift_id);

      if (findShift) {
        const timeLabels = [];
        const dataShiftTimes = [];
        const timeIn = moment(findShift.time_in, "HH:mm").format("H");
        const timeOut = moment(findShift.time_out, "HH:mm").format("H");
        let periodsInDay;

        if (parseInt(timeIn) > parseInt(timeOut)) {
          periodsInDay = moment
            .duration(
              moment(findShift.time_out, "HH:mm")
                .add(1, "day")
                .diff(moment(findShift.time_in, "HH:mm"))
            )
            .as("hours");
        } else {
          periodsInDay = moment
            .duration(
              moment(findShift.time_out, "HH:mm").diff(
                moment(findShift.time_in, "HH:mm")
              )
            )
            .as("hours");
        }

        for (let i = 0; i <= periodsInDay; i++) {
          dataShiftTimes.push({
            time: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
          });
          timeLabels.push({
            title: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            dataIndex: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),
            align: "center",
            key: moment(findShift.time_in, "HH:mm")
              .add(i, "hour")
              .format("HH:mm"),

            render: (data_table, unit) => {
              if (unit.unit == "%") {
                return <div>{Math.floor(data_table) + " %"}</div>;
              } else {
                return <div>{Math.floor(data_table)}</div>;
                // return console.log("tes", unit);
              }
            },
          });
        }
        setColumns([...initialColumns, ...timeLabels]);
        setShiftTimes(dataShiftTimes);
      }
    }
  };

  const handleChangeShift = (value) => {
    setSelectedShift({
      shift_id: value.value,
      shift_name: value.label,
    });
  };

  const makeInitialData = () => {
    // let [labels, values, sublevels] = initialData.map(_.values).unzip().value();
    setLabels(labels);
    setValues(values);
    setSublevels(sublevels);
  };

  const mapSubData = (index) => {
    let [a, b, c] = _(sublevels[index]).map(_.values).unzip().value();
    setLabels(a);
    setValues(b);
    setSublevels(c);
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/master-area/list/departement`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/line-process/listmain`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          department: data.plan_name,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getSubProcess = async (keyword) => {
    
    
    console.log(data);
        await Axios.get(`${url}/line-process/listsub`, {
            params: {
                keyword,
                limit: perpage,
                department: data.plan_name,
                main_process_name: data.main_process_name,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                setSubProcess(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
          });
    
};


  const getLine = async (keyword) => {

    console.log(data);
    await axios
      .get(`${url}/line-process/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          department: data.plan_name,
          sub_process_name: data.sub_process_name,
          main_process_name: data.main_process_name,

         
      
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/scan-spcard/listpart`, {
        params: {
         
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getOperator = async (keyword) => {

    console.log(data);
    await axios
      .get(`${url}/scan-spcard/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setFOperator(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);

    let heijunka_acc = [];
    let actual_acc = [];
    let actual = [];
    let heijunka = [];
    let efficiency = [];
    let itemName = [];

    axios
      .get(`${url}/dashboard-new`, {
        params: {
          keyword,
          columns: [
            "date",
            "main_process",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_name",
            "plan_id",
            "plan_name",
            "user_id",
            "user_full_name",
            "actual",
            "plan",
            "out",
            "shift_id",
            "shift_name",
          ],
          perpage: perpage,
          page,
          order: sort.order,
          plan_id: data.plan_id,
          main_process: data.main_process_name,
          line_process_id: data.line_process_id,
          part_number: data.part_number,
          user_id: data.user_id,
          shift: selectedShift.shift_name,
          start_date: moment(data.start_date).format("DD-MM-YYYY"),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        // const data_bar = res.data.data_bar;
        // // const pluckedShiftTime = _.pluck(shiftTimes, "time");
        // const filteredDataBar = data_bar.filter((bar) => {
        //   return bar.label;
        // });

        const data_bar = res.data.data_bar;
        console.log('Data bar', data_bar);
        const pluckedShiftTime = _.pluck(shiftTimes, "time");
        const filteredDataBar = data_bar.filter((bar) => {
          return pluckedShiftTime.includes(bar.label);
        });

        setDataNG({
          data: res.data.data_ng.data,
          labels: res.data.data_ng.label,
        });

        setDataBreakdown({
          data: res.data.data_breakdown.data,
          labels: res.data.data_breakdown.label,
        });

        setPlanPerJam(res.data.data_table);
        setCycleTimes(res.data.data_cycle_time);
        setActplan(res.data);
        for (const dataObj of filteredDataBar) {
          itemName.push(dataObj.label);
          heijunka.push(parseInt(dataObj.heijunka));
          heijunka_acc.push(parseInt(dataObj.heijunka_acc));
          actual.push(parseInt(dataObj.actual));
          actual_acc.push(parseInt(dataObj.actual_acc));
          efficiency.push(parseInt(dataObj.efficiency));
        }

        setItemData({
          labels: itemName,
          datasets: [
            {
              label: "Heijunka Acc",
              data: heijunka_acc,
              type: "line",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            {
              label: "Actual Acc",
              data: actual_acc,
              type: "line",
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
            },
            {
              label: "Efficiency",
              data: efficiency,
              type: "line",
              backgroundColor: "red",
              borderColor: "red",
            },
            {
              label: "Heijunka",
              data: heijunka,
              backgroundColor: "#FF8C00	",
              borderColor: "#FF8C00",
              yAxisID: "Kiri",
            },
            {
              label: "Actual",
              data: actual,
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
              yAxisID: "Kiri",
            },
          ],
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchData = () => {
    setLoading(true);

    let heijunka_acc = [];
    let actual_acc = [];
    let actual = [];
    let heijunka = [];
    let efficiency = [];
    let itemName = [];

    axios
      .get(`${url}/dashboard-new`, {
        params: {
          keyword,
          columns: [
            "date",
            
            "main_process",
            "sub_process",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_name",
            "plan_id",
            "plan_name",
            "user_id",
            "user_full_name",
            "actual",
            "plan",
            "out",
            "shift_id",
            "shift_name",
          ],
          perpage: perpage,
          page,
          order: sort.order,
          plan_id: data.plan_id,
          main_process: data.main_process_name,
          sub_process: data.sub_process_name,
          line_process_id: data.line_process_id,
          part_number: data.part_number,
          user_id: data.user_id,
          shift: selectedShift.shift_name,
          start_date: moment(data.start_date).format("DD-MM-YYYY"),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // const data_bar = res.data.data_bar;
        // // const pluckedShiftTime = _.pluck(shiftTimes, "time");
        // const filteredDataBar = data_bar.filter((bar) => {
        //   return bar.label;
        // });

        const data_bar = res.data.data_bar;
        const pluckedShiftTime = _.pluck(shiftTimes, "time");
        const filteredDataBar = data_bar.filter((bar) => {
          return pluckedShiftTime.includes(bar.label);
        });

        setDataNG({
          data: res.data.data_ng.data,
          labels: res.data.data_ng.label,
        });

        setDataBreakdown({
          data: res.data.data_breakdown.data,
          labels: res.data.data_breakdown.label,
        });

        setPlanPerJam(res.data.data_table);
        setCycleTimes(res.data.data_cycle_time);
        setActplan(res.data);
        for (const dataObj of filteredDataBar) {
          itemName.push(dataObj.label);
          heijunka.push(parseInt(dataObj.heijunka));
          heijunka_acc.push(parseInt(dataObj.heijunka_acc));
          actual.push(parseInt(dataObj.actual));
          actual_acc.push(parseInt(dataObj.actual_acc));
          efficiency.push(parseInt(dataObj.efficiency));
        }

        setItemData({
          labels: itemName,
          datasets: [
            {
              label: "Heijunka Acc",
              data: heijunka_acc,
              type: "line",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            {
              label: "Actual Acc",
              data: actual_acc,
              type: "line",
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
            },
            {
              label: "Efficiency",
              data: efficiency,
              type: "line",
              backgroundColor: "red",
              borderColor: "red",
            },
            {
              label: "Heijunka",
              data: heijunka,
              backgroundColor: "#FF8C00	",
              borderColor: "#FF8C00",
              yAxisID: "Kiri",
            },
            {
              label: "Actual",
              data: actual,
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
              yAxisID: "Kiri",
            },
          ],
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedShift) {
      getData();
    }
  }, [selectedShift, data, shiftTimes]);

  const options = {
    plugins: {
      datalabels: {
        display: true,
        align: "top",
        color: "black",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        gridLines: {
          display: true,
        },
      },
      y: {
        display: true,
        position: "right",
        scaleLabel: {
          display: true,
          beginAtZero: true,
        },
      },
      y1: {
        display: false,
        scaleLabel: {
          display: true,
          beginAtZero: true,
        },
      },
    },
  };

  const handleChangeViewDepartment = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleChangeViewMain = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        sub_processes: [],
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        sub_processes: [],
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [name]: value,
      });
    }
  };

  const handleChangeViewLine = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const checkTime = (time) => {
    if (time < 10) {
      time = "0" + time;
    } // add zero in front of numbers < 10
    return time;
  };

  const handleChangeViewPart = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_number`]: null,
      });
    }
  };

  const handleChangeViewOperator = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_full_name`]: value.label,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_full_name`]: null,
        [name]: value,
      });
    }
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value,
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
      });
    }
  };

  const downloadTemplateData = () => {
    setLoading(true);
    axios
      .get(`${url}/dashboard/download-data`, {
        params: {
          keyword,
          columns: [
            "date",
            "main_process_id",
            "main_process_name",
            "sub_process_name",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_name",
            "plan_id",
            "plan_name",
            "user_id",
            "user_full_name",
            "actual",
            "plan",
            "out",
            "shift_id",
          ],
  

          perpage: perpage,
          page,
          order: sort.order,
          plan_id: data.plan_id,
          main_process: data.main_process_name,
          sub_process: data.sub_process_name,
          line_process_id: data.line_process_id,
          part_number: data.part_number,
          user_id: data.user_id,
          shift: selectedShift.shift_name,
          shift_id: selectedShift.shift_id,

          start_date: moment(data.start_date).format("DD-MM-YYYY"),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        setLoading(false);
        fileDownload(
          res.data,
          "Export dashboard" +
            " " +
            moment(new Date()).format("yyyy-MMM-DD hh:mm:ss") +
            ".xlsx"
        );
      });
  };

  const downloadTemplateDataTraceability = () => {
   
    axios
      .get(`${url}/dashboard/download-data-traceability`, {
        params: {
          keyword,
          columns: [
            "date",
            "main_process_id",
            "main_process_name",
            "sub_process_name",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_name",
            "plan_id",
            "plan_name",
            "user_id",
            "user_full_name",
            "actual",
            "plan",
            "out",
            "shift_id",
          ],
  

          perpage: perpage,
          page,
          order: sort.order,
          plan_id: data.plan_id,
          main_process: data.main_process_name,
          sub_process: data.sub_process_name,
          line_process_id: data.line_process_id,
          part_number: data.part_number,
          user_id: data.user_id,
          shift: selectedShift.shift_name,
          shift_id: selectedShift.shift_id,

          start_date: moment(data.start_date).format("DD-MM-YYYY"),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        
        fileDownload(
          res.data,
          "Export dashboard traceabillity" +
            " " +
            moment(new Date()).format("yyyy-MMM-DD hh:mm:ss") +
            ".xlsx"
        );
      });
  };

  return localStorage.getItem("token") ? (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            style={{ textAlign: "center", fontSize: "40pt", padding: "0" }}
            type="inner"
            className="title-dashboard"
            title="Daily Production Control (Plan VS Actual) "
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={[16, 20]}>
          <Col xs={24} md={6} lg={4}>
            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Shift
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) => handleChangeShift(value)}
                  showSearch
                  filterOption={false}
                  value={{
                    label: selectedShift.shift_name,
                    value: selectedShift.shift_id,
                  }}
                >
                  {shifts &&
                    shifts.map((shift) => {
                      return (
                        <Option value={shift._id} key={shift._id}>
                          {shift.name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>
            <Card>
              <Space direction="vertical" size={12}>
                Date
                <DatePicker
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  onChange={handleChangeDate}
                  picker="date"
                  value={data.start_date}
                  format="DD-MMM-YYYY"
                />
              </Space>
            </Card>

            <Card>
              <Space direction="vertical" size={12}>
                Department
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewDepartment("plan", value)
                  }
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                >
                  {Departments &&
                    Departments.map((plan) => {
                      return (
                        <Option value={plan._id} key={plan._ide}>
                          {plan.name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Main Process
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewMain("main_process", value)
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                >
                  {Mains &&
                    Mains.map((main) => {
                      return (
                        <Option
                          value={main.main_process}
                          key={main.main_process}
                        >
                          {main.main_process}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Sub Process
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewMain("sub_process", value)
                  }
                  onFocus={() => getSubProcess("")}
                  showSearch
                  onSearch={(value) => getSubProcess(value)}
                  filterOption={false}
                >
                  {subProcess &&
                    subProcess.map((main) => {
                      return (
                        <Option
                          value={main.sub_process}
                          key={main.sub_process}
                        >
                          {main.sub_process}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Line Process
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewLine("line_process", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                >
                  {Lines &&
                    Lines.map((line) => {
                      return (
                        <Option
                          value={line._id}
                          key={line._id}
                        >
                          {line.name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: "10" }}>
              <Space direction="vertical" size={12}>
                Part Number
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) => handleChangeViewPart("part", value)}
                  onFocus={() => getPart("")}
                  showSearch
                  onSearch={(value) => getPart(value)}
                  filterOption={false}
                >
                  {parts &&
                    parts.map((part) => {
                      return (
                        <Option value={part.part_number} key={part.part_number}>
                          {part.part_number}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Operator
                <Select
                  allowClear="true"
                  style={{
                    fontSize: 10,
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                  }}
                  className="width"
                  labelInValue
                  onChange={(value) => handleChangeViewOperator("user", value)}
                  onFocus={() => getOperator("")}
                  showSearch
                  onSearch={(value) => getOperator(value)}
                  filterOption={false}
                >
                  {Operators &&
                    Operators.map((user) => {
                      return (
                        <Option value={user.scan_by_id} key={user.scan_by_id}>
                          {user.scan_by_name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                <Button
                  onClick={searchData}
                  type="primary"
                  style={{
                    width: 168,
                    marginLeft: -18,
                    marginRight: -18,
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                  }}
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Space>
            </Card>
          </Col>
          <Col xs={24} md={18} lg={20}>
            <Card style={{ marginBottom: 10 }}>
              <Row gutter={[16, 20]}>
                <Col xs={12} md={18} lg={20}>
                  <Bar data={itemData} options={options} />
                </Col>

                <Col xs={24} md={6} lg={4}>
                  <div className="contentTotal">
                    <div className="card">
                      <div className="card-header card-header-color-green">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PLAN
                        </h4>
                      </div>

                      <div
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan ? actplan.plan : 0}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-orange">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          ACTUAL
                        </h4>
                      </div>

                      <div
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan ? actplan.actual : 0}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-yellow">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PLAN ACHIEVMENT
                        </h4>
                      </div>

                      <div
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {Math.floor(actplan.achievment)} %
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          (Actual / Plan) * 100
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-red">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          LOST TIME
                        </h4>
                      </div>

                      <div
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan.hours}:{actplan.minutes}
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          Total Breakdown
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-red">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PRODUCTIVITY
                        </h4>
                      </div>

                      <div
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {parseFloat(actplan.productivity).toFixed(2)}
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          Act/(WH+OT)-Lost Time
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card
              style={{
                marginBottom: 10,
                width: 1000,
              }}
            >

<Row justify="space-between">
          <Col>
          

          
         
          </Col>
          <Col>
          <Space>
          <Can accessTo="Export Traceabillity Dashboard">
            <Button
              loading={loading}
                onClick={downloadTemplateDataTraceability}
                type="primary"
                style={{
                  display: "flex",
                  marginBottom: 15,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                  marginLeft: "auto",
                }}
              >
                <ExportOutlined />
                Export Traceabillity
              </Button>
            </Can>
            <Can accessTo="Export Dashboard">
          <Button
              loading={loading}
                onClick={downloadTemplateData}
                type="primary"
                style={{
                  display: "flex",
                  marginBottom: 15,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                  marginLeft: "auto",
                }}
              >
                <ExportOutlined />
                Export Production
              </Button>
              </Can>
             </Space>

          </Col>
        </Row>

              

              <Table
                style={{ marginTop: 20 }}
                size="small"
                scroll={{ x: 1000 }}
                maxWidth="190"
                bordered
                dataSource={planperjam}
                columns={columns}
                rowKey={(data, index) => index}
                loading={loading}
              />
            </Card>
            <Card style={{ width: 1000 }}>
              <Table
                style={{ marginTop: 20 }}
                size="small"
                scroll={{ x: 1000 }}
                maxWidth="190"
                dataSource={cycleTimes}
                columns={[
                  ...columns.filter((x) => {
                    const getShift = shifts.find(
                      (x) => x._id === selectedShift.shift_id
                    );

                    if (moment(x.title, "HH:mm").isValid()) {
                      if (getShift) {
                        const time = moment(x.title, "HH:mm"),
                          beforeTime = moment(
                            getShift.time_in,
                            "HH:mm"
                          ).subtract(1, "hour"),
                          afterTime = moment(getShift.time_out, "HH:mm").add(
                            1,
                            "hour"
                          );

                        const isTimeValid =
                          getShift.name === "SHIFT 1"
                            ? time.isBetween(beforeTime, afterTime)
                            : time.isAfter(beforeTime) ||
                              time.isBefore(afterTime);

                        return isTimeValid;
                      }
                    } else {
                      return true;
                    }
                  }),
                ]}
                rowKey={(data, index) => index}
                bordered
                pagination={false}
                loading={loading}
              />
            </Card>

            <Row>
              <Col span={12}>
                <Card>
                  <Doughnut
                    data={{
                      labels: dataNG.labels,

                      datasets: [
                        {
                          data: dataNG.data,
                          backgroundColor: [
                            "#FF8C00",
                            "#2e76ab",
                            "#6bafd6",
                            "#9dcae1",
                            "#c6dbf0",
                            "#e5550e",
                          ],
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        datalabels: {
                          display: true,
                          align: "bottom",
                          backgroundColor: "#ccc",
                          borderRadius: 3,
                          font: {
                            size: 18,
                          },
                        },
                      },

                      responsive: true,
                      maintainAspectRatio: false,
                      scaleShowVerticalLines: false,
                      showInLegend: true,
                      legend: {
                        display: true,
                        position: "left",
                        maxWidth: 100,
                      },
                    }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Doughnut
                    data={{
                      labels: dataBreakdown.labels,

                      datasets: [
                        {
                          data: dataBreakdown.data,
                          backgroundColor: [
                            "#455a64",
                            "#a2d3fa",
                            "#5ea3d9",
                            "#5891bc",
                            "#517e9e ",
                            "#4b6c81 ",
                          ],
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              const minutes = context.parsed;
                              const today = moment().startOf("day");
                              const formattedHours = moment()
                                .startOf("day")
                                .add(minutes, "minutes");
                              const diff = moment.duration(
                                formattedHours.diff(today)
                              );
                              return `${checkTime(
                                diff.asHours() > 1
                                  ? Math.floor(diff.asHours())
                                  : 0
                              )} : ${checkTime(Math.floor(diff.minutes()))}`;
                            },
                          },
                        },
                      },

                      responsive: true,
                      maintainAspectRatio: false,
                      scaleShowVerticalLines: false,
                      showInLegend: true,
                      legend: {
                        display: true,
                        position: "left",
                        maxWidth: 100,
                      },
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Fragment>
  ) : (
    <Redirect to="/login" />
  );
}

export default Dashboard;

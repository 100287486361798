import { Button, Col, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./MappingProcessForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function MappingProcessForm({ history, match }) {
  const [data, setData] = useState({
    plan_id: "",
    plan_name: "",
    main_process_id: "",
    main_process_name: "",
    main_processes: [],
    line_processes: [],
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [lines, setLines] = useState([]);
  const [mains, setMains] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/mapping-process/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // const { line_id, line_name, pos_id, pos_name, permissions } =
        //   res.data.data;
        setData({
          ...data,
          plan_id: res.data.data.plan_id,
          plan_name: res.data.data.plan_name,
          main_process_id: res.data.data.main_process_id,
          main_process_name: res.data.data.main_process_name,
          // main_processes: res.data.data.mapping_process_main_detail.map((x) => {
          //   return {
          //     key: x.main_process_id,
          //     value: x.main_process_id,
          //     label: x.main_process_name,
          //   };
          // }),
          line_processes: res.data.data.mapping_process_line_detail.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      plan_id: "",
      plan_name: "",
      main_process_id: "",
      main_process_name: "",
      main_processes: "",
      line_processes: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(data.role);
    axios
      .post(
        match.params.id
          ? `${url}/mapping-process/${match.params.id}`
          : `${url}/mapping-process`,
        {
          plan_id: data.plan_id,
          plan_name: data.plan_name,
          main_process_id: data.main_process_id,
          main_process_name: data.main_process_name,
          main_processes: data.main_processes,
          line_processes: data.line_processes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/mapping-process");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/line-process/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/main-process/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/plan/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectMultiple = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/mapping-process">
            <LeftOutlined />
            Mapping Process
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Mapping Process" : "Add Mapping Process"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                Department <span className="error-text">*</span>
                <Select
                  // disabled
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("plan", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {departments &&
                    departments.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.plan_name && (
                  <span className="error-text">{errors.plan_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                Main Process Name <span className="error-text">*</span>
                <Select
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("main_process", value)
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                  // mode="multiple"

                  value={{
                    key: data.main_process_id,
                    label: data.main_process_name,
                  }}
                >
                  {mains &&
                    mains.map((main) => {
                      return (
                        <Option value={main._id} key={main._id}>
                          {main.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_process_name && (
                  <span className="error-text">
                    {errors.main_process_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group">
                Line Process Name <span className="error-text">*</span>
                <Select
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("line_processes", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  mode="multiple"
                  value={data.line_processes}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option value={line._id} key={line._id}>
                          {line.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_processes && (
                  <span className="error-text">{errors.line_processes[0]}</span>
                )}
              </div>
              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  Submit
                </Button>
                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
            <Col md={6} xs={24}>
              {/* <div className="avatar-form">
                <div className="mb-20">
                  <Avatar
                    src={
                      data.photo_url
                        ? data.photo_url
                        : data.photo
                        ? URL.createObjectURL(data.photo)
                        : profile
                    }
                    size={100}
                  />
                </div>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                  accept="image/jpg, image/png"
                >
                  {data.photo ? (
                    <Button type="danger" onClick={handleRemove}>
                      <i className="mdi mdi-close mr-5" />
                      Delete Foto
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      style={{ backgroundColor: "#1b2086" }}
                    >
                      <i className="mdi mdi-upload mr-5" />
                      Upload Foto
                    </Button>
                  )}
                </Upload>
              </div> */}
              {/* <div className="permission">
                <div className="form-group">
                  <label className="form-label">Hak Akses</label>
                  <Checkbox.Group
                    className="permission-checkbox"
                    onChange={(value) => handleChange("permissions", value)}
                    value={data.permissions}
                    options={[
                      {
                        label: "Pengguna",
                        value: "user",
                      },
                      {
                        label: "Warga",
                        value: "civil",
                      },
                      {
                        label: "Data Laporan",
                        value: "report",
                      },
                      {
                        label: "Informasi",
                        value: "information",
                      },
                    ]}
                  />
                </div>
              </div> */}
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default MappingProcessForm;

import logo from "../../assets/sanoh.png";
import profile from "../../assets/profile.jpg";
import "./Navbar.css";
import { Transition } from "react-transition-group";
import { useState } from "react";

const searchTransitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function Navbar({ history, data }) {
  const [account, setAccount] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("fullname");
    localStorage.removeItem("photo");
    localStorage.removeItem("data");
    localStorage.removeItem("menus");
    localStorage.removeItem("active");
    localStorage.removeItem("permissions");
    history.push("/login");
  };
  // const handleProfile = () => {
  //   localStorage.setItem("active", "master");
  //   history.push("/user/edit/" + data._id);
  // };
  return (
    <div className="kelola-navbar">
      <div className="kelola-left-navbar">
        <img src={logo} className="kelola-logo" alt="logo" />
        {/* <p>RW 09 Perum Karaba Indah</p> */}
      </div>
      <div className="kelola-right-navbar">
        <div className="kelola-flex">
          <div
            className="kelola-profile-wrapper"
            onClick={() => setAccount(!account)}
          >
            <div className="kelola-profile-name">
              <h4>{data && data.full_name}</h4>
              <span>{localStorage.getItem("fullname")}</span>
            </div>
            <img
              src={data && data.photo_url ? data.photo_url : profile}
              // src={
              //   localStorage.getItem("photo") === false
              //     ? profile
              //     : localStorage.getItem("photo")
              // }
              className="kelola-profile"
              alt="profile"
            />

            <Transition in={account} timeout={100} appear unmountOnExit>
              {(state) => (
                <>
                  <svg
                    width="34"
                    height="29"
                    viewBox="0 0 34 29"
                    fill="none"
                    className="kelola-bubble-dropdown kelola-bubble-dropdown-profile"
                    style={searchTransitionStyle[state]}
                  >
                    <path
                      d="M1.41154 28L17 0.999999L32.5885 28H1.41154Z"
                      fill="white"
                      stroke="#f1f1f5"
                    />
                  </svg>
                  <div
                    className="kelola-profile-toggle"
                    style={searchTransitionStyle[state]}
                  >
                    <ul>
                      {/* <li onClick={handleProfile}>
                        <i className="feather-user" />
                        Profile
                      </li> */}
                      <li onClick={handleLogout}>
                        <i className="feather-log-out" /> Logout
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

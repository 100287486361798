import { Button, Card, Col, Dropdown, Input, Menu, Row, Space, Table, message } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined, FileExcelOutlined, BarcodeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Axios from "../../../config/axios";
import fileDownload from "js-file-download";
import Can from "../../../components/Can";

function GenerateLabel({ history }) {
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);

    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: "created_at",
        order: "ascend",
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [isImport, setIsImport] = useState(false);
    const [error, setError] = useState(null);
    const [spcard, setSpcard] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        Axios
            .get('/generate-label', {
                params: {
                    keyword,
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                    columns: [
                        'part_number',
                        'part_name',
                    ]
                },
            })
            .then((res) => {
                setSpcard(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownloadTemplate = () => {
        Axios.get('generate-spcard/import/template', { responseType: 'blob' }).then((res) => {
            fileDownload(res.data, 'template_generate_sp_card.xlsx');
        }).catch((err) => {
            if (err.response) {
                message.error(err.response.data.message);
            }
        })
    }

    const handleGenerate = (id) => {
        setLoading(true);
        Axios
            .get(`/generate-spcard/${id}/generate`)
            .then(({ data }) => {
                message.success(data.message);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = [
        {
            title: "InforLN",
            dataIndex: "inforln",
            key: "inforln",
            sorter: true,
        },
        {
            title: "SNP Type",
            dataIndex: "snp_type",
            key: "snp_type",
            sorter: true,
        },
        {
            title: "Part Number",
            dataIndex: "part_number",
            key: "part_number",
            sorter: true,
        },
        {
            title: "Part Name",
            dataIndex: "part_name",
            key: "part_name",
            sorter: true,
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            sorter: true,
        },
        {
            title: "Main Process Name",
            dataIndex: "main_process_name",
            key: "main_process_name",
            sorter: true,
        },
        {
            title: "Line Process Name",
            dataIndex: "line_process_name",
            key: "line_process_name",
            sorter: true,
        },
        {
            title: "Qty S.P. Card",
            dataIndex: "qty_spcard",
            key: "qty_spcard",
            sorter: true,
        },
        {
            title: "Qty Current",
            dataIndex: "jenis_mesin",
            key: "jenis_mesin",
            sorter: true,
        },
        {
            title: "Date Delivery",
            dataIndex: "no_mesin",
            key: "no_mesin",
            sorter: true,
        },
        {
            title: "Akumulasi Total Print",
            dataIndex: "no_memori",
            key: "no_memori",
            sorter: true,
        },
       
    ];

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Generate Label</li>
            </ul>
            <Card>
                <Row justify="space-between">
                    <Col>
                        <Space>
                        <Can accessTo="Generate Label Intenal">
                                <Link to="/generate-spcard/create">
                                    <Button
                                        type="primary"
                                        style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}>
                                        <BarcodeOutlined />
                                        Genereate Label Internal
                                    </Button>
                                </Link>
                            </Can>

                            <Can accessTo="Merge Generate Label">
                                <Link to="/generate-spcard/create">
                                    <Button
                                        type="primary"
                                        style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}>
                                       Merge
                                    </Button>
                                </Link>
                            </Can>

                        </Space>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => setKeyword(value)}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                    <Table
                            scroll={{ x: true }}
                            columns={columns}
                            dataSource={spcard}
                            pagination={{ total, showSizeChanger: true }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            expandable={{
                                expandedRowRender: (record) => (<></>),
                                onExpand: (expanded,{_id}) => setExpandedRowKeys(expanded ? [_id] : []),
                                expandedRowKeys
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default GenerateLabel;
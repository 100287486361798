import React, { useEffect, useState } from 'react';
import Axios from '../../../../config/axios';
import { Select, message } from 'antd';

function FilterLabelInternal({ onFinish }) {
    const [departments, setDepartments] = useState([]);
    const [data, setData] = useState([]);

    const [page] = useState(1);
    const [perpage] = useState(15);
    const [sort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    useEffect(() => {
        handleFilter();
    }, [data]);

    const handleChangeSelectMultiple = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
    };

    const getDepartments = async (keyword = null) => {
        await Axios.get(`/plan/list`, {
            params: {
                keyword,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleFilter = () => {
        const objToArray = (data) =>
            Object.keys(data).reduce((newData, key) => {
                const values = data[key].map((item) => item.value);
                if (values.length > 0) {
                    newData[key] = values;
                }
                return newData;
            }, {});

        const transformedData = objToArray(data);
        if (Object.keys(transformedData).length > 0) {
            onFinish(transformedData);
        } else {
            onFinish();
        }
    };

    return (
        <div>
            <Select
                allowClear
                onClear={() => setData([])}
                style={{ width: '200px' }}
                labelInValue
                onChange={(value) =>
                    handleChangeSelectMultiple('department', value)
                }
                onFocus={() => getDepartments()}
                showSearch
                onSearch={(value) => getDepartments(value)}
                filterOption={false}
                mode={'multiple'}
                placeholder="Filter Departemen"
            >
                {departments &&
                    departments.map((dept) => (
                        <Select.Option key={dept.name} value={dept.name}>
                            {dept.name}
                        </Select.Option>
                    ))}
            </Select>
        </div>
    );
}

export default FilterLabelInternal;

import { Button, Col, Input, message, Row, Select, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ShiftForm.css";
import profile from "../../assets/profile.jpg";
import axios from "axios";
import { url } from "../../constant/url";
import moment from "moment";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function ShiftForm({ history, match }) {
  const [data, setData] = useState({
    name: "",
    time: "",
  });

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };
  // const onChange (time, timeString){
  function onChange(time, timeString) {
    // console.log(time, timeString);
  }

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/shift/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // const { name, time} = res.data.data;
        setData({
          ...data,
          name: res.data.data.name,
          // time1: res.data.data.time[0],
          // time2: res.data.data.time[1],
          // time: res.data.data.time,
          time_in: res.data.data.time_in,
          time_out: res.data.data.time_out,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      name: "",
      time: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(data.role);
    axios
      .post(
        match.params.id ? `${url}/shift/${match.params.id}` : `${url}/shift`,
        {
          name: data.name,
          // time: data.time,
          time_in: data.time_in,
          time_out: data.time_out,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/shift");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  // const waktu1 = "12:00";
  // const waktu2 = "13:00";
  // console.log("time 1", data.time1);
  // console.log(data);

  const format = "HH:mm";

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/shift">
            <LeftOutlined />
            Shift
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Shift" : "Add Shift"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Shift Group <span className="error-text">*</span>
                </label>
                <Input
                  value={data.name}
                  className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
              </div>
              {/* <div className="form-group">
                <label className="form-label">
                  Time <span className="error-text">*</span>
                </label>
                <TimePicker.RangePicker
                  style={{ width: 693 }}
                  // value={data.time}
                  value={
                    match.params.id && [
                      moment(data.time[0], "HH:mm"),
                      moment(data.time[1], "HH:mm"),
                    ]
                  }
                  onChange={(value) => handleChange("time", value)}
                  format="HH:mm"
                />
                {errors && errors.time && (
                  <span className="error-text">{errors.time[0]}</span>
                )}
              </div> */}
              <div className="form-group">
                <label className="form-label">
                  Time In <span className="error-text">*</span>
                </label>
                <TimePicker
                  style={{ width: '100%' }}
                  className={errors && errors.time_in ? "is-error" : ""}
                  value={match.params.id && moment(data.time_in, format)}
                  format={format}
                  onChange={(value) => handleChange("time_in", value)}
                />
                {errors && errors.time_in && (
                  <span className="error-text">{errors.time_in[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Time Out <span className="error-text">*</span>
                </label>
                <TimePicker
                  style={{ width: '100%' }}
                  className={errors && errors.time_out ? "is-error" : ""}
                  value={match.params.id && moment(data.time_out, format)}
                  format={format}
                  onChange={(value) => handleChange("time_out", value)}
                />
                {errors && errors.time_out && (
                  <span className="error-text">{errors.time_out[0]}</span>
                )}
              </div>
              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  Submit
                </Button>
                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default ShiftForm;

import React, { useEffect, useMemo, useState } from 'react'

function Can({ accessTo, children }) {
    const [hasAccess, setHasAccess] = useState(false);

    const checkItem = useMemo(() => {
        return (data, target) => {
            if (!data) return false;
            
            return data.some(item => {
                if (item && item.name === target) return true;
                if (item && item.children) return checkItem(item.children, target);
                return false;
            });
        };
    }, []);

    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("menus")) || [];
        setHasAccess(checkItem(permissions, accessTo));
    }, [accessTo, checkItem]);
    
    return hasAccess ? children : null;
}
export default Can
import {
    Button,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Space,
    Table,
    message,
} from 'antd';
import { MergeCellsOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import Axios from '../../../../config/axios';

function MergeLabelInternal({
    isVisible,
    setIsVisible,
    onFinish,
    data: record,
    disabled,
}) {
    const [loading, setLoading] = useState(false);
    const [qtyRequired, setQtyRequired] = useState(0);
    const [mergeSPC, setMergeSPC] = useState([]);
    const [currentQty, setCurrentQty] = useState(0);
    const [mergeAble, setMergeAble] = useState(true);
    const [mergeFrom, setMergeForm] = useState(null);

    const [mergeForm] = useForm();
    const searchRef = useRef();
    const qtyReqRef = useRef();
    useEffect(() => {
        if (record) {
            handleReset();
            setMergeAble(record.qty_required !== 0);
        }
    }, [record]);

    const onCancel = () => {
        setIsVisible(false);
        handleReset();
    };

    const getSPCard = (value) => {
        Axios.get(`/generate-label/qrcode`, {
            params: {
                spcard: value,
                part_number: record && record.part_number,
                qrcode: record && record.qrcode,
            },
        })
            .then(({ data }) => {
                const spc = data.data;

                let take_from_spc = Math.min(
                    qtyRequired,
                    spc.qty_current,
                );

                const current = currentQty + take_from_spc;
                const required = qtyRequired - take_from_spc
                setCurrentQty(current);
                setQtyRequired(required);
                if (required === 0) {
                    setMergeAble(false)
                }
                
                const newData = {
                    id: spc._id,
                    qrcode: spc.qrcode,
                    qty_current: spc.qty_current,
                    qty: take_from_spc,
                };

                setMergeSPC((prev) => {
                    // Check if the id already exists in the array
                    const idExists = prev.some(
                        (item) => item.id === newData.id,
                    );
                    return idExists ? prev : [...prev, newData];
                });
                mergeForm.setFieldsValue({spcard: ''})
            })
            .catch((err) => {
                message.error(err.response.data.message);
            });
    };

    const handleReset = () => {
        const qty_required = record.qty_erp - record.qty_current;
        setCurrentQty(record.qty_current);
        setQtyRequired(qty_required);
        setMergeSPC([]);
        setMergeForm(null);
    };

    const handleSubmit = (data) => {
        const payload = {
            ...data,
            id: record._id,
            merge_from: mergeSPC,
        };
        setLoading(true);
        Axios.post(`/generate-label/merge`, payload)
            .then(({ data }) => {
                onFinish();
                setIsVisible(false);
                message.success(data.message);
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    var err = error.response.data.message;
                    let mapped = '';

                    for (const key in err) {
                        if (err.hasOwnProperty(key)) {
                            mapped += `${err[key][0]}\n`;
                        }
                    }
                    message.error(mapped);
                } else {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <React.Fragment>
            <Button
                type="primary"
                icon={<MergeCellsOutlined />}
                onClick={() => setIsVisible(true)}
                disabled={disabled}
            >
                Merge
            </Button>
            <Modal
                title="Merge SP CARD DEB"
                visible={isVisible}
                onCancel={onCancel}
                width={800}
                footer={[
                    <Button key="btn-cancel" type="ghost" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="btn-import"
                        type="primary"
                        onClick={() => mergeForm.submit()}
                        disabled={loading || mergeAble}
                    >
                        Submit
                    </Button>,
                ]}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <table style={{ width: '100%' }} cellPadding={4}>
                        <tbody>
                            <tr>
                                <th className="kelola-text-primary">
                                    Part Number
                                </th>
                                <td>{record && record.part_number}</td>
                                <th className="kelola-text-primary">Line</th>
                                <td>{record && record.line_process}</td>
                            </tr>
                            <tr>
                                <th className="kelola-text-primary">
                                    Part Name
                                </th>
                                <td>{record && record.part_name}</td>
                                <th className="kelola-text-primary">
                                    Current Qty
                                </th>
                                <td>{currentQty}</td>
                            </tr>
                            <tr>
                                <th className="kelola-text-primary">
                                    QTY ERP (SNP)
                                </th>
                                <td>{record && record.qty_erp}</td>
                                <th className="kelola-text-primary">
                                    Qty Required
                                </th>
                                <td>{qtyRequired}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Form
                        layout="vertical"
                        style={{ width: '100%' }}
                        form={mergeForm}
                        onFinish={handleSubmit}
                    >
                        <Row style={{ display: 'flex', gap: '2rem' }}>
                            <Form.Item
                                label="S.P. CARD CUT"
                                name="spcard"
                                style={{ width: '50%' }}
                            >
                                <Input.Search
                                    ref={searchRef}
                                    disabled={!mergeAble}
                                    allowClear="true"
                                    onSearch={(v) => getSPCard(v)}
                                />
                            </Form.Item>
                            <Form.Item label="Qty Current" name="qty_current">
                                <InputNumber disabled />
                            </Form.Item>
                        </Row>
                    </Form>

                    {mergeSPC.length > 0 && (
                        <Table
                            columns={[
                                {
                                    title: 'S.P. CARD CUT',
                                    dataIndex: 'qrcode',
                                    key: 'qrcode',
                                },
                                {
                                    title: 'Qty Current',
                                    dataIndex: 'qty_current',
                                    key: 'qty_current',
                                },
                                {
                                    title: 'Qty',
                                    dataIndex: 'qty',
                                    key: 'qty',
                                },
                            ]}
                            dataSource={mergeSPC}
                            pagination={false}
                        />
                    )}
                </Space>
            </Modal>
        </React.Fragment>
    );
}

export default MergeLabelInternal;

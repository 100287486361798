import { Button, Modal, Result, message } from 'antd';
import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';

export default function DeleteConfirmModal({
    isVisible,
    setIsVisible,
    data: record,
    onFinish,
}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const _ids = record
            .filter((data) => data.status_generate === 0)
            .map((d) => d._id);
        setData(_ids);
    }, [record, isVisible]);
    const onCancel = () => {
        setData([]);
        setIsVisible(false);
    };

    const onDelete = () => {
        setLoading(true);
        Axios.post('/master-spcard/destroy/all', { data: data })
            .then((res) => {
                setData([]);
                setIsVisible(false);
                onFinish();
                message.success(res.data.message);
            })
            .catch((res) => {
                if (res.response) {
                    const response = res.response.data;
                    message.error(response.message);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Delete All SP Card"
            visible={isVisible}
            onCancel={onCancel}
            footer={[
                <Button key="btn-cancel" type="ghost" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="btn-confirm"
                    type="primary"
                    danger
                    onClick={() => onDelete()}
                    disabled={data.length === 0 || loading}
                    loading={loading}
                >
                    Delete
                </Button>,
            ]}
        >
            <Result
                status="warning"
                title="Warning"
                subTitle={`${record.length - data.length} of ${record.length} selected SP Card(s) has been released and cannot be deleted. You can permanently delete ${data.length} SP Card(s). Are you sure?`}
            />
        </Modal>
    );
}

import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Card,
    Upload,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url, storage_path } from '../../constant/url';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    ImportOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';
import imgDefault from '../../assets/img-empty.png';
import moment from 'moment';

function MasterBOM(history) {
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            sorter: true,
        },
        {
            title: 'Desc Product',
            dataIndex: 'desc_product',
            key: 'desc_product',
            sorter: true,
        },
        {
            title: 'Part No',
            dataIndex: 'part_no',
            key: 'part_no',
            sorter: true,
        },
        {
            title: 'Customer Part No',
            dataIndex: 'customer_partno',
            key: 'customer_partno',
            sorter: true,
        },
        {
            title: 'Product Type',
            dataIndex: 'product_type',
            key: 'product_type',
            sorter: true,
        },
        {
            title: 'Product Model',
            dataIndex: 'product_model',
            key: 'product_model',
            sorter: true,
        },
        // {
        //   title: "",
        //   dataIndex: "_id",
        //   key: "id",
        //   sorter: false,
        //   fixed: "right",
        //   width: 50,
        //   render: (id) => {
        //     return (
        //       <>
        //         <Dropdown
        //           className="pointer"
        //           overlay={
        //             <Menu>
        //               <Menu.Item key="1">
        //                 <Link to={`/master-bom/edit/${id}`}>
        //                   {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
        //                   <EditOutlined /> Edit
        //                 </Link>
        //               </Menu.Item>
        //               <Menu.Item key="3">
        //                 <span onClick={() => handleDelete(id)}>
        //                   {/* <i className="mdi mdi-delete mr-5" /> */}
        //                   <DeleteOutlined />
        //                   Delete
        //                 </span>
        //               </Menu.Item>
        //             </Menu>
        //           }
        //           trigger={["click"]}
        //         >
        //           <span className="pointer" onClick={(e) => e.preventDefault()}>
        //             <i className="mdi mdi-dots-vertical" />
        //           </span>
        //         </Dropdown>
        //       </>
        //     );
        //   },
        // },
    ];

    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(null);
    const [MItem, setMItem] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [bomSync, setBomSync] = useState([]);
    const [buttonAccess, setButtonAccess] = useState(null);

    const [loadingDetail, setLoadingDetail] = useState(false);
    const [pageDetail, setPageDetail] = useState(1);
    const [keywordDetails, setKeywordDetails] = useState('');
    const [perPageDetail, setPerPageDetail] = useState(10);
    const [rowDetails, setRowDetails] = useState([]);

    const [sortDetail, setSortDetail] = useState({
        sort: 'updated_at',
        order: 'descend',
    });

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    useEffect(() => {
        getData();
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            localStorage.getItem('menus') &&
            JSON.parse(localStorage.getItem('menus'));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.permission_type === 'button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.permission_type === 'button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/master-bom`, {
                params: {
                    keyword,
                    columns: [
                        'item',
                        'part_no',
                        'customer_partno',
                        'product_type',
                        'product_model',
                    ],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setMItem(res.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getBomSync = (id = selectedId) => {
        setLoadingDetail(true);
        axios
            .get(`${url}/master-sync/${id}`, {
                params: {
                    keyword: keywordDetails,
                    perpage: perPageDetail,
                    page: pageDetail,
                    sort: sortDetail.sort,
                    order: sortDetail.order,
                },

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setBomSync(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                } else {
                    message.error('Backend not yet Started');
                }
            })
            .finally(() => {
                setLoadingDetail(false);
            });
    };

    const handleChangeDetailsTable = (pagination, filter, sorter) => {
        setPageDetail(pagination.current);
        setSortDetail({
            sort: sorter.field,
            order: sorter.order,
        });
        setPerPageDetail(pagination.pageSize);
    };

    const handleChangeDetails = (data) => {
        const index = data[data.length - 1];
        console.log(index);
        setRowDetails([index]);
        if (index) {
            const data2 = index.split(';');
            console.log(data2);
            setSelectedId(data2[0]);
            getBomSync(data2[0]);
        }
    };
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/master-bom/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const downloadTemplate = () => {
        axios
            .get(`${url}/master-bom/download`, {
                params: {
                    keyword,
                    columns: ['description', 'name', 'code'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'template_master_bom.xlsx');
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const handleExport = (delivery_note_no) => {
        console.log(delivery_note_no);
        axios
            .get(`${url}/master-bom/export`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, `Export master-bom.xlsx`);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const onClickExport = () => {
        handleExport();
    };

    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    // const handleCancel = () => {
    //   setIsModalVisible(false);
    // };

    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        axios
            .post(`${url}/master-bom/import`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data.type);
                    if (err.response.data.type === 'error') {
                        const errorMessage = err.response.data.message.replace(
                            /\n/g,
                            '<br>',
                        ); // Mengganti karakter baris baru (\n) dengan tag <br>
                        Modal.error({
                            title: 'Failed!',
                            content: (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: errorMessage,
                                    }}
                                />
                            ), // Menggunakan dangerouslySetInnerHTML untuk memasukkan HTML langsung
                        });
                    } else {
                        const errorsKey = Object.keys(err.response.data.errors);
                        const errors = err.response.data.errors;

                        message.open({
                            message: `Error!: ${err.response.data.message}`,
                            description: (
                                <div>
                                    Error Details:
                                    {errorsKey.map((error) => {
                                        return <div>{errors[error][0]}</div>;
                                    })}
                                </div>
                            ),
                        });
                    }

                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const getSync = () => {
        const columns = [
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
            },
            {
                title: 'Sequence',
                dataIndex: 'sequence',
                key: 'sequence',
            },
            {
                title: 'Item Material',
                dataIndex: 'item_material',
                key: 'item_material',
            },
            {
                title: 'Part No Material',
                dataIndex: 'part_no_material',
                key: 'part_no_material',
            },
            {
                title: 'Desc Item Material',
                dataIndex: 'desc_item_material',
                key: 'desc_item_material',
            },
            {
                title: 'Old Part No',
                dataIndex: 'old_partno',
                key: 'old_partno',
            },
            {
                title: 'Net Qty',
                dataIndex: 'net_qty',
                key: 'net_qty',
            },
            {
                title: 'Unit',
                dataIndex: 'unit',
                key: 'unit',
            },
            {
                title: 'SNP',
                dataIndex: 'snp',
                key: 'snp',
            },
            {
                title: 'Warehouse',
                dataIndex: 'warehouse',
                key: 'warehouse',
            },
        ];

        return (
            <>
                <Row>
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 1000 }}
                            columns={columns}
                            rowKey={(data) => data._id}
                            loading={loadingDetail}
                            dataSource={bomSync}
                            // size="small"
                            onChange={handleChangeDetailsTable}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return localStorage.getItem('token') ? (
        <div>
            <ul className="breadcumb">
                <li className="active">Master BOM</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        {/* {buttonAccess &&
                        buttonAccess.find(
                          (x) => x.url === "master-bom/create"
                        ) && (
                          <Link to="/master-bom/create">
                            <Button 
                            type="primary"
                            style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}>
                              <PlusOutlined />
                              Add
                            </Button>
                          </Link>
                        )}
            {buttonAccess &&
                        buttonAccess.find(
                          (x) => x.url === "master-bom/import"
                        ) && (
                          <Button
                          onClick={showModal}
                          type="primary"
                          style={{
                            backgroundColor: "#1b2086",
                            borderColor: "#1b2086",
                            marginLeft: 10,
                          }}
                        >
                          <ImportOutlined />
                          Import
                        </Button>
                        )} */}
                        {/* {buttonAccess &&
                        buttonAccess.find(
                          (x) => x.url === "master-bom/export"
                        ) && (
                    
                            <Button 
                            type="primary"
                            style={{ backgroundColor: "#1b2086", borderColor: "#1b2086", marginLeft: "10px" }} onClick={onClickExport}>
                              <ExportOutlined />
                              Export
                            </Button>
                          
                        )} */}
                        {/* {buttonAccess &&
                        buttonAccess.find(
                          (x) => x.url === "master-bom/download"
                        ) && (
                          
                            <Button 
                            onClick={downloadTemplate}
                            type="primary"
                            style={{ backgroundColor: "#1b2086", borderColor: "#1b2086", marginLeft: "10px" }}>
                              <ExportOutlined />
                              Download Template
                            </Button>
                          
                        )}
              */}
                        <Modal
                            title="Import Material BOM"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={closeModal}
                        >
                            <Card className="body-data">
                                <div name="control-hooks">
                                    <div name="file" label="File">
                                        <Fragment>
                                            <Upload
                                                onRemove={handleRemove}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={[]}
                                            >
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Choose File{' '}
                                                    <ExportOutlined />
                                                </Button>
                                            </Upload>
                                            {data.file && (
                                                <React.Fragment
                                                    children={[
                                                        <Button
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="danger"
                                                            ghost
                                                        >
                                                            <DeleteOutlined />
                                                        </Button>,
                                                    ]}
                                                >
                                                    {data.file.name
                                                        ? data.file.name
                                                        : data.file}
                                                </React.Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                    <div>
                                        <Button
                                            loading={loading}
                                            onClick={onSaveData}
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-button"
                                            style={{
                                                marginRight: 10,
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            htmlType="reset"
                                            onClick={handleRemove}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Modal>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setPage(1);
                                setKeyword(value);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 1500 }}
                            columns={columns}
                            dataSource={MItem.data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            expandable={{
                                expandedRowRender: getSync,
                                onExpandedRowsChange: (data) =>
                                    handleChangeDetails(data),
                                expandedRowKeys: rowDetails,
                            }}
                            rowKey={(data) => data.item}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    ) : (
        <Redirect to="/login" />
    );
}

export default MasterBOM;

import { Button, Form, Modal, Upload, message } from 'antd';
import React, { Fragment, useState } from 'react';
import { ImportOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from '../../../../config/axios';

function Import({ isVisible, setIsVisible, onFinish }) {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleBeforeUpload = (f) => {
        setFiles(f)
        return false;
    }

    const onCancel = () => {
        setIsVisible(false)
        setFiles([])
    }

    const handleImport = () => {
        if (files.length === undefined) {
            var formData = new FormData();
            formData.append("file", files);
            setLoading(true);
            Axios.post("/generate-spcard/import", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(({ data: res }) => {
                setIsVisible(false)
                onFinish()
                setFiles([]);
                message.success(res.message);
            }).catch((err) => {
                // setIsVisible(false)
                if (err.response) {
                    console.log(err.response.data.type);
                    if (err.response.data.type === "error") {
                      const errorMessage = err.response.data.message.replace(
                        /\n/g,
                        "<br>"
                      ); 
                      Modal.error({
                        title: "Failed!",
                        content: (
                          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        ),
                      });
                    } else {
                      const errorsKey = Object.keys(err.response.data.errors);
                      const errors = err.response.data.errors;
          
                      message.open({
                        message: `Error!: ${err.response.data.message}`,
                        description: (
                          <div>
                            Error Details:
                            {errorsKey.map((error) => {
                              return <div>{errors[error][0]}</div>;
                            })}
                          </div>
                        ),
                      });
                    }
          
                    if (err.response.status === 401) {
                      localStorage.removeItem("token");
                    }
                  } else {
                    message.error("Backend not yet Started");
                  }
            }).finally(() => setLoading(false))
        }
    }


    return (
        <>
            <Button
                type="primary"
                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                onClick={() => setIsVisible(true)}
            >
                <ImportOutlined />
                Import
            </Button>
            <Modal
                title="Import SP Card"
                visible={isVisible}
                onCancel={onCancel}
                footer={[
                    <Button
                        key="btn-cancel"
                        type='ghost'
                        onClick={onCancel}>Cancel</Button>,
                    <Button
                        key="btn-import"
                        type='primary'
                        disabled={loading}
                        onClick={handleImport}>Import</Button>,
                ]}
            >
                <Form>
                    <Form.Item>
                        <Upload
                            onRemove={() => setFiles([])}
                            beforeUpload={handleBeforeUpload}
                            fileList={[]}
                            accept='.xlsx'
                        >
                            <Button icon={<UploadOutlined />}>Click to Import File</Button>
                        </Upload>

                        {files &&
                            (<Fragment
                                children={[
                                    <Button onClick={() => setFiles([])} type="danger" ghost>
                                        <DeleteOutlined />
                                    </Button>
                                ]}
                            >
                                {files.name ? files.name : files}
                            </Fragment>)
                        }
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}

export default Import